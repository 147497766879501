import { Component, OnInit, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHandler, HttpRequest, HttpParams, HttpHeaders } from '@angular/common/http';
import { Globals } from '../providers/globals/globals';

import { Observable, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { Subscription } from 'rxjs/Subscription';
import { MessageServiceService } from '../services/message-service.service';

@Component({
	selector: 'app-viewentryadim8',
	templateUrl: './viewentryadim8.component.html',
	styleUrls: ['./viewentryadim8.component.css']
})
export class ViewentryAdim8Component implements OnInit {
	subscription: Subscription;
	GlobalYonetim: any;
	EntryId;
	SayfaDegisti = 0;
	PageData: any;
	Dosyalar = [];
	Yuklendi = false;
	link = '';
	Yil = '';

	response: string;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private toastr: ToastrService,
		private globals: Globals,
		private messageService: MessageServiceService,
		private zone: NgZone,
		private http: HttpClient,
	) {

	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	ngOnInit() {
		this.route.paramMap.subscribe(params => {
			this.EntryId = params.get('EntryId');
		});

		if (this.globals.Yonetim.Yuklendi === true) {
			this.GlobalYonetim = this.globals.YilaGoreGlobalYonetimAl(this.Yil);
			window.scrollTo(0, 0);
			this.DataYukle();
		}

		this.subscription = this.messageService.getMessage().subscribe(message => {
			if (message.text === 'Yonetim') {
				this.GlobalYonetim = this.globals.YilaGoreGlobalYonetimAl(this.Yil);
				this.DataYukle();
			}
		});

	}

	DataYukle() {
		var result = this.globals.projeler.filter(function (elem, i, rep) {
			return elem.Id === this;
		}, parseInt(this.EntryId));
		if (result.length) {

			this.PageData = JSON.parse(JSON.stringify(result[0]));
			if (this.PageData.EBook_Onay !== 3) {
				this.zone.run(() => {
					this.router.navigateByUrl('/giden');
				});
			}
			this.Dosyalar = [];
			this.PageData['Adim8'].forEach(element => {
				this.Dosyalar.push(element);
			});
			this.Yil = this.PageData['Yil'];
			this.GlobalYonetim = this.globals.YilaGoreGlobalYonetimAl(this.Yil);
			this.Yuklendi = true;
			// this.DataDetay();
		}
	}

	TarihFormatla(Tarih) {
		var options = {
			weekday: 'short',
			year: 'numeric',
			month: 'short',
			day: 'numeric',
			hour: 'numeric',
			minute: 'numeric',
			seconds: 'numeric'
		};
		return Tarih.toLocaleDateString("tr-TR", options);
	}

	showSuccess(mesaj: string) {
		this.toastr.success(mesaj, 'Tebrikler!');
	}

	showError(mesaj: string) {
		this.toastr.error(mesaj, 'Hata!');
	}

	showWarning(mesaj: string) {
		this.toastr.warning(mesaj, 'Dikkat!');
	}

	showInfo(mesaj: string) {
		this.toastr.info(mesaj);
	}

	AdimDegistir(sayfa: number) {
		if (typeof sayfa !== 'undefined') {
			this.router.navigateByUrl('/viewentry/' + this.EntryId + '/' + sayfa);
		}
		else {
			this.showError("Bir hata oluştu.");
		}
	}

	formatBytes(bytes, decimals = 2) {
		if (bytes === 0) return '0 Bytes';

		const k = 1024;
		const dm = decimals < 0 ? 0 : decimals;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

		const i = Math.floor(Math.log(bytes) / Math.log(k));

		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	}

	slugify(text) {
		var trMap = {
			'çÇ': 'c',
			'ğĞ': 'g',
			'şŞ': 's',
			'üÜ': 'u',
			'ıİ': 'i',
			'öÖ': 'o',
			' ': '_',
			'?': '_',
			'-': '_',
			'&': '_'
		};
		for (var key in trMap) {
			text = text.replace(new RegExp('[' + key + ']', 'g'), trMap[key]);
		}
		return text.replace(/[^-a-zA-Z0-9\s]+/ig, '') // remove non-alphanumeric chars
			.replace(/\s/gi, "-") // convert spaces to dashes
			.replace(/[-]+/gi, "-") // trim repeated dashes
			.toLowerCase();

	}

	DosyaType(text) {
		if (text === 'text/plain') {
			text = "txt"
		}
		else if (text === "application/msword") {
			text = "Word"
		} else if (text === "image/jpeg") {
			text = "Resim"
		}
		else if (text === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
			text = "Excel"
		}
		else if (text === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
			text = "Word"
		}
		else {

		}
		return text
	}
}
