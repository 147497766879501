<section id="IcerikContainer">
    <div class="dev-wrapper dev-entrant-home dev-wrapper-entry-home dev-flex-container IcerikSubContainer">
        <article id="content" class="dev-flex-child">
            <header class="dev-entrant-layout">
                <h1>Giden Başvurular</h1>
                <div style="margin-top: 10px;">
                    <ul>
                        <li>Bu sayfada <strong>tamamlanmamış</strong> başvurularınız listelenmektedir.</li>
                        <li>Yeni Başvuru oluşturduğunuzda, başvuru sürecinizde eksik bıraktığınız adımları görebilirisiniz.</li>
                        <li>Başvuru ID’nize veya Kampanya Adı’nıza tıklayarak başvurunuzu düzenleyebilirsiniz.</li>
                        <li>Başvurunuzu tamamladıktan sonra sistem otomatik olarak yaptığınız başvuruyu “Gönderilmiş” alanına gönderecektir.</li>
                        <li>Eğer başvurunuzu <strong>tamamen silmek isterseniz</strong> “Sil” butonunu kullanabilirsiniz. Ancak, başvurunuzu sildiğiniz noktada girmiş olduğunuz bütün veriler sistemden silinecektir.
                            <strong>Silme işlemini geri almak mümkün değildir.</strong></li>
                    </ul>
                </div>
            </header>
            <table class="tbl-entrant-entry-listing" *ngFor="let item of PageData; let i = index;">
                <tbody>
                    <tr style="height: 20px;">
                        <td style="width: 110px;"><a [routerLink]="['/viewentry/',item.Id,1]"><strong>#{{item.Id}}</strong></a></td>
                        <td><a [routerLink]="['/viewentry/',item.Id,1]"><strong>{{item.MarkaAdi}}</strong> <span style="color: black; font-weight: 600;"> ( {{ item.GondermeTarihi  | date:'dd.MM.yyyy HH:mm' }} )</span></a>
                            <br>{{item.KampanyaAdi}}
                            <br>{{item.Kategori}} - {{item.SubKategori}}
                        </td>
                    </tr>
                </tbody>
            </table>
            <!-- <table class="tbl-entrant-entry-listing" *ngFor="let item of PageData; let i = index;">
                <tbody>
                    <tr style="height: 20px;">
                        <td rowspan="5" style="padding-top: 0px;padding-bottom: 0px;"><a [routerLink]="['/viewentry/',item.Id,1]"><strong>#{{item.Id}}</strong></a></td>
                        <td colspan="11" style="padding-top: 0px;padding-bottom: 0px;"><a [routerLink]="['/viewentry/',item.Id,1]"><strong>{{item.MarkaAdi}} / {{item.FormuDolduran.Ad}}</strong></a>
                        </td>

                    </tr>
                    <tr style="height: 20px;">
                        <td colspan="11" style="padding-top: 0px;padding-bottom: 0px;">{{item.Adim2.KampanyaAdi}}</td>
                    </tr>
                    <tr style="height: 20px;">
                        <td colspan="11" style="padding-top: 0px;padding-bottom: 0px;">{{item.Kategori}} - {{item.SubKategori}}
                        </td>
                    </tr>
                    <tr>
                        <td style="text-align: center;">Kategori Seçimi</td>
                        <td style="text-align: center;">Künye</td>
                        <td style="text-align: center;">Sorular A</td>
                        <td style="text-align: center;">Sorular B</td>
                        <td style="text-align: center;">Sorular C</td>
                        <td style="text-align: center;">Sorular D</td>
                        <td style="text-align: center;">Dosya Yükleme</td>
                        <td style="text-align: center;">Sayfa Sayısı Doğrulama</td>
                        <td style="text-align: center;">İzinler Belgesi</td>
                        <td style="text-align: center;">Başvur</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td style="text-align: center;">
                            <i class="fa fa-check" style="color: green;" *ngIf="item.Adim1Durum == true"></i>
                            <i class="fa fa-times" style="color: red;" *ngIf="item.Adim1Durum === false"></i>
                        </td>
                        <td style="text-align: center;">
                            <i class="fa fa-check" style="color: green;" *ngIf="item.Adim2Durum == true"></i>
                            <i class="fa fa-times" style="color: red;" *ngIf="item.Adim2Durum === false"></i>
                        </td>
                        <td style="text-align: center;">
                            <i class="fa fa-check" style="color: green;" *ngIf="item.Adim3Durum == true"></i>
                            <i class="fa fa-times" style="color: red;" *ngIf="item.Adim3Durum === false"></i>
                        </td>
                        <td style="text-align: center;">
                            <i class="fa fa-check" style="color: green;" *ngIf="item.Adim4Durum == true"></i>
                            <i class="fa fa-times" style="color: red;" *ngIf="item.Adim4Durum === false"></i>
                        </td>
                        <td style="text-align: center;">
                            <i class="fa fa-check" style="color: green;" *ngIf="item.Adim5Durum == true"></i>
                            <i class="fa fa-times" style="color: red;" *ngIf="item.Adim5Durum === false"></i>
                        </td>
                        <td style="text-align: center;">
                            <i class="fa fa-check" style="color: green;" *ngIf="item.Adim6Durum == true"></i>
                            <i class="fa fa-times" style="color: red;" *ngIf="item.Adim6Durum === false"></i>
                        </td>
                        <td style="text-align: center;">
                            <i class="fa fa-check" style="color: green;" *ngIf="item.Adim7Durum == true"></i>
                            <i class="fa fa-times" style="color: red;" *ngIf="item.Adim7Durum === false"></i>
                        </td>
                        <td style="text-align: center;">
                            <i class="fa fa-check" style="color: green;" *ngIf="item.Adim8DurumEbook == true"></i>
                            <i class="fa fa-times" style="color: red;" *ngIf="item.Adim8DurumEbook === false"></i>
                        </td>
                        <td style="text-align: center;">
                            <i class="fa fa-check" style="color: green;" *ngIf="item.Adim9Durum == true"></i>
                            <i class="fa fa-times" style="color: red;" *ngIf="item.Adim9Durum === false"></i>
                        </td>
                        <td style="text-align: center;">
                            <i class="fa fa-check" style="color: green;" *ngIf="item.Adim10Durum == true"></i>
                            <i class="fa fa-times" style="color: red;" *ngIf="item.Adim10Durum === false"></i>
                        </td>
                        <td></td>
                    </tr>
                </tbody>
            </table> -->
        </article>
    </div>
</section>