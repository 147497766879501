<section id="IcerikContainer">
    <div class="dev-wrapper dev-entrant-home dev-wrapper-entry-home dev-flex-container IcerikSubContainer">
        <article id="content" class="dev-flex-child">
            <div class="wizard-v4-content">
                <div class="wizard-form">
                    <div class="wizard-header">
                        <h3 class="heading">Başvuru Güncelleme</h3>
                        <!-- <p>Formu doldurup bir sonraki adıma geçiniz, veya istediğiniz adıma geçebilirsiniz.</p> -->
                    </div>
                    <div class="form-register view" action="#" method="post" *ngIf="Yuklendi === true && PageData.EBook_Onay === 3">
                        <div id="form-total" role="application" class="wizard clearfix">
                            <div class="steps clearfix">
                                <ul role="tablist">
                                    <li role="tab" class="done">
                                        <a (click)="AdimDegistir(1)">
                                            <div class="title">
                                                <span class="step-cizgi-left renkli"></span>
                                                <span class="step-cizgi-right renkli"></span>
                                                <span class="step-icon">1</span>
                                                <span class="step-text">Kategori Seçimi</span>
                                                <span class="badge" *ngIf="PageData.Adim1Durum === false">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="done">
                                        <a (click)="AdimDegistir(2)">
                                            <div class="title">
                                                <span class="step-cizgi-left renkli"></span>
                                                <span class="step-cizgi-right renkli"></span>
                                                <span class="step-icon">2</span>
                                                <span class="step-text">Künye</span>
                                                <span class="badge" *ngIf="PageData.Adim2Durum === false">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="done">
                                        <a (click)="AdimDegistir(3)">
                                            <div class="title">
                                                <span class="step-cizgi-left renkli"></span>
                                                <span class="step-cizgi-right renkli"></span>
                                                <span class="step-icon">3</span>
                                                <span class="step-text">Özet</span>
                                                <span class="badge" *ngIf="PageData.Adim3Durum === false">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="done">
                                        <a (click)="AdimDegistir(4)">
                                            <div class="title">
                                                <span class="step-cizgi-left renkli"></span>
                                                <span class="step-cizgi-right renkli"></span>
                                                <span class="step-icon">4</span>
                                                <span class="step-text">Sorular A</span>
                                                <span class="badge" *ngIf="PageData.Adim4Durum === false">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="done">
                                        <a (click)="AdimDegistir(5)">
                                            <div class="title">
                                                <span class="step-cizgi-left renkli"></span>
                                                <span class="step-cizgi-right renkli"></span>
                                                <span class="step-icon">5</span>
                                                <span class="step-text">Sorular B</span>
                                                <span class="badge" *ngIf="PageData.Adim5Durum === false">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="done">
                                        <a (click)="AdimDegistir(6)">
                                            <div class="title">
                                                <span class="step-cizgi-left renkli"></span>
                                                <span class="step-cizgi-right renkli"></span>
                                                <span class="step-icon">6</span>
                                                <span class="step-text">Sorular C</span>
                                                <span class="badge" *ngIf="PageData.Adim6Durum === false">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="done">
                                        <a (click)="AdimDegistir(7)">
                                            <div class="title">
                                                <span class="step-cizgi-left renkli"></span>
                                                <span class="step-cizgi-right renkli"></span>
                                                <span class="step-icon">7</span>
                                                <span class="step-text">Sorular D</span>
                                                <span class="badge" *ngIf="PageData.Adim8Durum === false">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="done">
                                        <a>
                                            <div class="title">
                                                <span class="step-cizgi-left renkli"></span>
                                                <span class="step-cizgi-right"></span>
                                                <span class="step-icon">8</span>
                                                <span class="step-text">Dosya Yükleme</span>
                                                <span class="badge" *ngIf="PageData.Adim8DurumEbook === false">!</span>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div class="content clearfix">

                                <form class="form form-horizontal AdimliForm" novalidate="">
                                    <p *ngIf="GlobalYonetim.Adim8.Header.length > 0" [innerHtml]="GlobalYonetim.Adim8.Header" style="max-width: none; width: 100%; margin-bottom: 10px"></p>

                                    <table class="Table">
                                        <thead>
                                            <tr>
                                                <th>Adı</th>
                                                <th>Türü</th>
                                                <th>Boyutu</th>
                                                <th>Tarihi</th>
                                                <th style="text-align:center;">Ön İzleme</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngIf="Dosyalar.length == 0">
                                                <td colspan="6" align="center">Yüklenmiş dosyanız bulunmamaktadır.</td>
                                            </tr>
                                            <tr *ngFor="let item of Dosyalar; let i = index;">
                                                <td *ngIf="item.Type !== 'Link'">{{item.DosyaAdi}}</td>
                                                <td *ngIf="item.Type === 'Link'"><a href="{{item.DosyaAdi}}" target="_blank">{{item.DosyaAdi}}</a></td>
                                                <td>{{DosyaType(item.Type)}}</td>
                                                <td>{{formatBytes(item.Boyut)}}</td>
                                                <td>{{item.Tarih | date:'dd.MM.yyyy HH:mm'}}</td>
                                                <td align="center">
                                                    <a *ngIf="item.Type !== 'Link'" target="_blank" href="https://api.effieturkiye.org/storage/dosyalar/{{EntryId}}/{{item.DosyaAdi}}" target="_blank" style="color: rgb(177, 152, 92);"><i
                                                            class="fas fa-eye"></i></a>
                                                    <a *ngIf="item.Type === 'Link'" target="_blank" href="{{item.DosyaAdi}}" target="_blank" style="color: rgb(177, 152, 92);"><i
                                                                class="fas fa-eye"></i></a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </form>

                            </div>
                            <div class="actions clearfix">
                                <ul role="menu" class="Pagination">
                                    <li (click)="AdimDegistir(7)"><a role="menuitem">Bir Önceki Adım</a></li>
                                    <!-- <li (click)="AdimDegistir(8)"><a role="menuitem">Bir Sonraki Adım</a></li> -->
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </article>
    </div>
</section>