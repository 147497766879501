import { Component, OnInit, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Globals } from '../providers/globals/globals';
import { HttpClient, HttpHandler, HttpRequest, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs/Subscription';
import { MessageServiceService } from '../services/message-service.service';

@Component({
	selector: 'app-editentryadim4',
	templateUrl: './editentryadim4.component.html',
	styleUrls: ['./editentryadim4.component.css']
})
export class EditentryAdim4Component implements OnInit {
	GlobalYonetim: any;
	subscription: Subscription;
	Adim = '';
	AdimSurdurulebilir = '';
	EntryId;
	PageData: any;
	OrgData: any;
	Yuklendi = false;
	response: string;
	Editor: any;
	_returnedURL = '';
	Editors = [];
	downloadURL: Observable<string>;
	Sorular: any = [];
	TumSoruCevaplar: any = [];
	Yil = '';

	ngOnDestroy() {

		this.subscription.unsubscribe();
	}

	EditorCreated(quill, id) {
		quill.id = id;
		this.Editors.push(quill);
		const toolbar = quill.getModule('toolbar');
		toolbar.addHandler('image', this.imageHandler.bind(this, this.Editors.length - 1));
	}

	imageHandler(index) {
		var TempDocId = Math.floor(Date.now() / 1000).toString();
		const Imageinput = document.createElement('input');
		Imageinput.setAttribute('type', 'file');
		Imageinput.setAttribute('accept', 'image/png, image/jpeg');
		Imageinput.addEventListener('change', () => {
			if (Imageinput.files !== null && Imageinput.files[0] !== null) {
				const file = Imageinput.files[0];
				let extension = '.jpg';
				if (file.type === 'image/jpeg') {
					extension = '.jpg'
				}
				else if (file.type === 'image/png') {
					extension = '.png'
				}

				// var can = new Blob([file], { type: file.type });
				const uploadData = new FormData();
				uploadData.append('file', file, file.name);
				uploadData.append('ProjeId', this.EntryId);

				const params = new HttpParams();
				// params = params.append("BranchId", this.BranchId.toString());
				// params = params.append("Sorting", (this.PageDataImage["data"].length + 1).toString());

				const headers = new HttpHeaders()
					.append('Authorization', 'Bearer ' + this.globals.User.Token);
				var url = 'https://api.effieturkiye.org/ProjeImages';

				this.http.post(url, uploadData, {
					// reportProgress: true,
					// observe: 'events',
					params: params,
					headers: headers
				})
					.subscribe(data => {
						// this._returnedURL = 'https://bnsorular.com/storage/photos/' + this.EntryId + '/' + data['filename'];
						this._returnedURL = data['filename'];
						const range = this.Editors[index].getSelection(true);
						this.Editors[index].insertEmbed(range.index, 'image', this._returnedURL);
						this.PageData.SoruCevap[this.Editors[index]['id']]['Cevap']['Cevap'] = this.Editors[index].root.innerHTML;
						// this.PageData.SoruCevap[this.Editors[index]['id']]['Cevap'] = this.Editors[index].root.innerHTML;
						this.toastr.success('Successfully Saved!', 'Success');
					},
						err => {
							// console.log('Error occured.');
							// console.log(err);
							if (err.status == 401) {

							}
							else {
								this.toastr.error('A connection error occurred, please refresh the page.');
							}
						});
				// this.ref = this.cloudstore.ref('/photos/' + this.EntryId + '/' + TempDocId + extension);
				// let that = this;
				// this.ref.put(file).then(function (snapshot) {

				// 	that._returnedURL = 'https://firebasestorage.googleapis.com/v0/b/' + snapshot.metadata.bucket + '/o/' + encodeURIComponent(snapshot.metadata.fullPath) + '?alt=media';					
				// 	const range = that.Editors[index].getSelection(true);
				// 	that.Editors[index].insertEmbed(range.index, 'image', that._returnedURL);
				// 	that.PageData[that.Adim][that.Editors[index]['id']]['Cevap'] = that.Editors[index].root.innerHTML;

				// }).catch(error => {
				// });
			}
		});
		Imageinput.click();
	}

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private toastr: ToastrService,
		private globals: Globals,
		private messageService: MessageServiceService,
		private zone: NgZone,
		private http: HttpClient,
	) {


	}

	ngOnInit() {
		this.route.data.subscribe(data => {
			this.Adim = data.Adim;
		});
		this.route.paramMap.subscribe(params => {
			this.EntryId = params.get('EntryId');
		});

		if (this.globals.Yonetim.Yuklendi === true) {
			this.GlobalYonetim = this.globals.YilaGoreGlobalYonetimAl(this.Yil);
			window.scrollTo(0, 0);
			this.DataYukle();
		}

		this.subscription = this.messageService.getMessage().subscribe(message => {
			if (message.text === 'Yonetim') {
				this.GlobalYonetim = this.globals.YilaGoreGlobalYonetimAl(this.Yil);
				window.scrollTo(0, 0);
				this.DataYukle();
			}
		});
	}

	DataYukle() {
		// console.log(this.Adim);
		var result = this.globals.projeler.filter(function (elem, i, rep) {
			return elem.Id === this;
		}, parseInt(this.EntryId));
		if (result.length) {
			this.zone.run(() => {
				this.PageData = JSON.parse(JSON.stringify(result[0]));
				this.OrgData = JSON.parse(JSON.stringify(result[0]));
				this.Yil = this.PageData['Yil'];
				this.GlobalYonetim = this.globals.YilaGoreGlobalYonetimAl(this.Yil);
				this.TumSoruCevaplar = JSON.parse(JSON.stringify(this.PageData['SoruCevap']));

				this.PageData['SoruCevap'] = this.PageData['SoruCevap'].filter(function (elem, i, rep) {
					return elem.Adim == this;
				}, this.Adim);
				console.log(this.PageData['SoruCevap'][2]["Cevap"]["Cevap"]);

				this.OrgData['SoruCevap'] = this.OrgData['SoruCevap'].filter(function (elem, i, rep) {
					return elem.Adim == this;
				}, this.Adim);

				if (this.PageData.Surdurulebilir === 1) {
					// console.log(this.Adim)
					this.Sorular = this.globals.SorularSurdurulebilir.filter(function (elem, i, rep) {
						return elem.Adim == this;
					}, this.Adim);
					// this.Sorular = this.globals.SorularSurdurulebilir;
				}
				else {
					// console.log(this.globals.Sorular);
					// console.log(this.Adim);
					this.Sorular = this.globals.Sorular.filter(function (elem, i, rep) {
						return elem.Adim == this;
					}, this.Adim);
					// console.log(this.Sorular);
				}

				this.Yuklendi = true;
				// if (this.PageData.Adim11Durum === 1) {
				// 	this.router.navigateByUrl('/dashboard');
				// }
			});

		}
	}

	RadioChanged(Index, RadioIndex) {
		this.PageData.SoruCevap[Index].Cevap['Radios'].forEach(element => {
			element.Value = false;
		});

		this.PageData.SoruCevap[Index].Cevap['Radios'][RadioIndex]['Value'] = true;
		this.PageData.SoruCevap[Index].Cevap['BelirtmekIstemiyorum'] = false;
	}

	BelirtmekIstemiyorum(Index) {
		this.PageData.SoruCevap[Index].Cevap['Radios'].forEach(element => {
			element.Value = false;
		});
		this.PageData.SoruCevap[Index].Cevap['BelirtmekIstemiyorum'] = true;
	}

	DigerCheckboxClick(State, Index, CheckBoxIndex) {
		if (this.Adim == "6") {
			return false;
		}
		this.PageData.SoruCevap[Index].Cevap.CheckBoxes[CheckBoxIndex].Value = State;
	}

	DigerCheckboxGrupClick(State, Index, CheckBoxGrupIndex, CheckBoxIndex) {
		if (this.Adim == "6") {
			return false;
		}
		this.PageData.SoruCevap[Index].Cevap.CheckBoxes[CheckBoxGrupIndex].SubCheckBoxes[CheckBoxIndex].Value = State;
		// this.PageData.SoruCevap[i].CheckBoxes[CheckBoxGrupIndex].SubCheckBoxes[CheckBoxIndex].Cevap = '';
	}

	CheckBoxChanged(Index, CheckBoxIndex) {
		if (this.Adim == "6") {
			return false;
		}
		if (this.PageData.SoruCevap[Index].Cevap['CheckBoxes'][CheckBoxIndex]['Type'] === 'CheckBox') {
			if (this.PageData.SoruCevap[Index].Cevap['CheckBoxes'][CheckBoxIndex]['Value'] === false) {
				this.PageData.SoruCevap[Index].Cevap['CheckBoxes'][CheckBoxIndex]['Value'] = true;
				return;
			}
			else {
				this.PageData.SoruCevap[Index].Cevap['CheckBoxes'][CheckBoxIndex]['Value'] = false;
			}
		}
	}

	CheckBoxTextChanged(Index, CheckBoxIndex) {
		if (this.PageData.SoruCevap[Index].Cevap['CheckBoxes'][CheckBoxIndex]['Cevap'].trim().length > 0) {
			this.PageData.SoruCevap[Index].Cevap['CheckBoxes'][CheckBoxIndex]['Value'] = true;
		}
		else {
			this.PageData.SoruCevap[Index].Cevap['CheckBoxes'][CheckBoxIndex]['Value'] = false;
		}
	}

	CheckBoxGrupChanged(Index, CheckBoxGrupIndex, CheckBoxIndex) {
		if (this.Adim == "6") {
			return false;
		}
		if (this.PageData.SoruCevap[Index].Cevap['CheckBoxes'][CheckBoxGrupIndex]['SubCheckBoxes'][CheckBoxIndex]['Type'] === 'CheckBox') {
			if (this.PageData.SoruCevap[Index].Cevap['CheckBoxes'][CheckBoxGrupIndex]['SubCheckBoxes'][CheckBoxIndex]['Value'] === false) {
				this.PageData.SoruCevap[Index].Cevap['CheckBoxes'][CheckBoxGrupIndex]['SubCheckBoxes'][CheckBoxIndex]['Value'] = true;
				return;
			}
			else {
				this.PageData.SoruCevap[Index].Cevap['CheckBoxes'][CheckBoxGrupIndex]['SubCheckBoxes'][CheckBoxIndex]['Value'] = false;
			}
		}
	}

	CheckBoxGrupTextChanged(Index, CheckBoxGrupIndex, CheckBoxIndex) {
		if (this.PageData.SoruCevap[Index].Cevap['CheckBoxes'][CheckBoxGrupIndex]['SubCheckBoxes'][CheckBoxIndex]['Cevap'].trim().length > 0) {
			this.PageData.SoruCevap[Index].Cevap['CheckBoxes'][CheckBoxGrupIndex]['SubCheckBoxes'][CheckBoxIndex]['Value'] = true;
		}
		else {
			this.PageData.SoruCevap[Index].Cevap['CheckBoxes'][CheckBoxGrupIndex]['SubCheckBoxes'][CheckBoxIndex]['Value'] = false;
		}
	}

	AdimDegistir(sayfa) {
		if (sayfa < 1) {
			if (this.Adim == "4") {
				if (sayfa === -1) {
					sayfa = 3;
				}
				else if (sayfa === 0) {
					sayfa = 5;
				}
			}
			else if (this.Adim == "5") {
				if (sayfa === -1) {
					sayfa = 4;
				}
				else if (sayfa === 0) {
					sayfa = 6;
				}
			}
			else if (this.Adim == "6") {
				if (sayfa === -1) {
					sayfa = 5;
				}
				else if (sayfa === 0) {
					sayfa = 7;
				}
			}
			else if (this.Adim == "7") {
				if (sayfa === -1) {
					sayfa = 6;
				}
				else if (sayfa === 0) {
					sayfa = 8;
				}
			}
		}

		var Tammi = true;
		for (let MainIndex = 0; MainIndex < this.PageData.SoruCevap.length; MainIndex++) {
			var element = this.PageData.SoruCevap[MainIndex];
			// console.log(element)
			if (element.Cevap.Type === 'SoruText') {

				if (typeof element.Cevap.Cevap === 'undefined') {
					element.Cevap.Cevap = '';
				}
				if (element.Cevap === null) {
					element.Cevap.Cevap = '';
				}
				if (element.Cevap.Cevap.trim().length === 0) {
					Tammi = false;
				}
			}
			else if (element.Cevap.Type === 'Radio') {
				var TrueSay = 0;
				for (let index = 0; index < element.Cevap.Radios.length; index++) {
					if (element.Cevap.Radios[index]['Value'] === true) {
						TrueSay++;
					}
				}
				if (element.Cevap.BelirtmekIstemiyorum) {
					TrueSay++;
				}
				if (TrueSay === 0) {
					Tammi = false;
				}
			}
			else if (element.Cevap.Type === 'CheckBox') {
				var TrueSay = 0;
				for (let index = 0; index < element.Cevap.CheckBoxes.length; index++) {
					if (element.Cevap.CheckBoxes[index]['Value'] === true) {
						TrueSay++;
					}
				}
				if (TrueSay === 0) {
					Tammi = false;
				}
			}
			else if (element.Cevap.Type === 'MultiCheckBox') {
				var TrueSay = 0;
				for (let index = 0; index < element.Cevap.CheckBoxes.length; index++) {
					for (let SubIndex = 0; SubIndex < element.Cevap.CheckBoxes[index]['SubCheckBoxes'].length; SubIndex++) {
						if (element.Cevap.CheckBoxes[index]['SubCheckBoxes'][SubIndex]['Value'] === true) {
							TrueSay++;
						}
					}
				}
				if (TrueSay === 0) {
					Tammi = false;
				}
			}
		};
		// console.log(this.PageData.SoruCevap)
		if (JSON.stringify(this.PageData) !== JSON.stringify(this.OrgData)) {
			// console.log("cevap farklı")

			let UpdateData = {
				'SoruCevap': this.PageData.SoruCevap,
				'Durum': Tammi,
				'Adim': this.Adim,
				'ProjeId': this.EntryId
			};
			// console.log(UpdateData);
			// return;

			const params = new HttpParams();
			let headers = new HttpHeaders();
			headers = headers.append('Accept', 'application/json, text/javascript, */*; q=0.01');
			headers = headers.append('Content-Type', 'application/json');
			headers = headers.append('Authorization', 'Bearer ' + this.globals.User.Token);

			this.http.post('https://api.effieturkiye.org/ProjeEbookSoruCevap', UpdateData, { params, headers }).subscribe(
				(response) => {
					const index = this.globals.projeler.findIndex(elem => elem.Id === Number(this.EntryId));
					this.globals.projeler[index] = response;
					if (this.Adim == "4") {
						this.showSuccess('Dördüncü adım kaydedildi, yönlendiriliyorsunuz.');
					}
					else if (this.Adim == "5") {
						this.showSuccess('Beşinci adım kaydedildi, yönlendiriliyorsunuz.');
					}
					else if (this.Adim == "6") {
						this.showSuccess('Altıncı adım kaydedildi, yönlendiriliyorsunuz.');
					}
					else if (this.Adim == "7") {
						this.showSuccess('Yedince adım kaydedildi, yönlendiriliyorsunuz.');
					}

					this.messageService.sendMessage('ProjeGuncellendi');
					this.router.navigateByUrl('/editentry/' + this.EntryId + '/' + sayfa);
				},
				error => {
					//  console.log('Error', error)
					this.showError('Bir hata oluştu, Lütfen tekrar deneyiniz');
				}
			);
		}
		else {
			// console.log("beleş geçiyor");
			this.router.navigateByUrl('/editentry/' + this.EntryId + '/' + sayfa);
		}

	}

	validateChange(Index) {
		// console.log("Index", Index);
		// if (this.OrgData.SoruCevap[Index].Cevap.DegisiklikYapildi == false) {
		// 	if (this.PageData.SoruCevap[Index].Cevap.Cevap !== this.OrgData.SoruCevap[Index].Cevap.Cevap) {
		// 		this.PageData.SoruCevap[Index].Cevap.DegisiklikYapildi = true;
		// 	}
		// 	else {
		// 		this.PageData.SoruCevap[Index].Cevap.DegisiklikYapildi = false;
		// 	}
		// }
	}

	showSuccess(mesaj: string) {
		this.toastr.success(mesaj, 'Tebrikler!');
	}

	showError(mesaj: string) {
		this.toastr.error(mesaj, 'Hata!');
	}

	showWarning(mesaj: string) {
		this.toastr.warning(mesaj, 'Dikkat!');
	}

	showInfo(mesaj: string) {
		this.toastr.info(mesaj);
	}
}