import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { EditentryAdim1Component } from './editentryadim1/editentryadim1.component';
import { EditentryAdim2Component } from './editentryadim2/editentryadim2.component';
import { EditentryAdim3Component } from './editentryadim3/editentryadim3.component';
import { EditentryAdim4Component } from './editentryadim4/editentryadim4.component';
import { EditentryAdim8Component } from './editentryadim8/editentryadim8.component';
import { EditentryAdim9Component } from './editentryadim9/editentryadim9.component';

import { ViewentryAdim1Component } from './viewentryadim1/viewentryadim1.component';
import { ViewentryAdim2Component } from './viewentryadim2/viewentryadim2.component';
import { ViewentryAdim3Component } from './viewentryadim3/viewentryadim3.component';
import { ViewentryAdim4Component } from './viewentryadim4/viewentryadim4.component';
import { ViewentryAdim8Component } from './viewentryadim8/viewentryadim8.component';

import { GelenComponent } from './gelen/gelen.component';
import { GidenComponent } from './giden/giden.component';
import { HomepageComponent } from './homepage/homepage.component';
import { DosyaOlusturComponent } from './dosyaolustur/dosyaolustur.component';


const routes: Routes = [
	{ path: '', redirectTo: '/home', pathMatch: 'full' },
	{ path: 'home', component: HomepageComponent },
	{ path: 'gelen', component: GelenComponent },
	{ path: 'giden', component: GidenComponent },
	{ path: 'dosyaolustur', component: DosyaOlusturComponent },

	{ path: 'editentry', redirectTo: '/gelen', pathMatch: 'full' },
	{ path: 'editentry/', redirectTo: '/gelen', pathMatch: 'full' },
	{ path: 'editentry/:EntryId/1', component: EditentryAdim1Component },
	{ path: 'editentry/:EntryId/2', component: EditentryAdim2Component },
	{ path: 'editentry/:EntryId/3', component: EditentryAdim3Component },
	{ path: 'editentry/:EntryId/4', component: EditentryAdim4Component, data: { Adim: "4" } },
	{ path: 'editentry/:EntryId/5', component: EditentryAdim4Component, data: { Adim: "5" } },
	{ path: 'editentry/:EntryId/6', component: EditentryAdim4Component, data: { Adim: "6" } },
	{ path: 'editentry/:EntryId/7', component: EditentryAdim4Component, data: { Adim: "7" } },
	{ path: 'editentry/:EntryId/8', component: EditentryAdim8Component },
	{ path: 'editentry/:EntryId/9', component: EditentryAdim9Component },
	
	{ path: 'viewentry', redirectTo: '/giden', pathMatch: 'full' },
	{ path: 'viewentry/', redirectTo: '/giden', pathMatch: 'full' },
	{ path: 'viewentry/:EntryId/1', component: ViewentryAdim1Component },
	{ path: 'viewentry/:EntryId/2', component: ViewentryAdim2Component },
	{ path: 'viewentry/:EntryId/3', component: ViewentryAdim3Component },
	{ path: 'viewentry/:EntryId/4', component: ViewentryAdim4Component, data: { Adim: "4" } },
	{ path: 'viewentry/:EntryId/5', component: ViewentryAdim4Component, data: { Adim: "5" } },
	{ path: 'viewentry/:EntryId/6', component: ViewentryAdim4Component, data: { Adim: "6" } },
	{ path: 'viewentry/:EntryId/7', component: ViewentryAdim4Component, data: { Adim: "7" } },
	{ path: 'viewentry/:EntryId/8', component: ViewentryAdim8Component },

  	{ path: '**', component: HomepageComponent }
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
	exports: [RouterModule]
})
export class AppRoutingModule { }
