<section id="IcerikContainer">
    <div class="dev-wrapper dev-entrant-home dev-wrapper-entry-home dev-flex-container IcerikSubContainer">
        <article id="content" class="dev-flex-child">
            <div class="wizard-v4-content">
                <div class="wizard-form">
                    <div class="wizard-header">
                        <h3 class="heading">Başvuru Güncelleme</h3>
                        <!-- <p>Formu doldurup bir sonraki adıma geçiniz, veya istediğiniz adıma geçebilirsiniz.</p> -->
                    </div>
                    <div class="form-register view" action="#" method="post" *ngIf="Yuklendi === true && PageData.EBook_Onay === 3">
                        <div id="form-total" role="application" class="wizard clearfix">
                            <div class="steps clearfix">
                                <ul role="tablist">
                                    <li role="tab" class="done">
                                        <a>
                                            <div class="title">
                                                <span class="step-cizgi-left renkli"></span>
                                                <span class="step-cizgi-right"></span>
                                                <span class="step-icon">1</span>
                                                <span class="step-text">Kategori Seçimi</span>
                                                <span class="badge" *ngIf="PageData.Adim1Durum === false">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="">
                                        <a (click)="AdimDegistir(2)">
                                            <div class="title">
                                                <span class="step-cizgi-left"></span>
                                                <span class="step-cizgi-right"></span>
                                                <span class="step-icon">2</span>
                                                <span class="step-text">Künye</span>
                                                <span class="badge" *ngIf="PageData.Adim2Durum === false">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="">
                                        <a (click)="AdimDegistir(3)">
                                            <div class="title">
                                                <span class="step-cizgi-left"></span>
                                                <span class="step-cizgi-right"></span>
                                                <span class="step-icon">3</span>
                                                <span class="step-text">Özet</span>
                                                <span class="badge" *ngIf="PageData.Adim3Durum === false">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="">
                                        <a (click)="AdimDegistir(4)">
                                            <div class="title">
                                                <span class="step-cizgi-left"></span>
                                                <span class="step-cizgi-right"></span>
                                                <span class="step-icon">4</span>
                                                <span class="step-text">Sorular A</span>
                                                <span class="badge" *ngIf="PageData.Adim4Durum === false">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="">
                                        <a (click)="AdimDegistir(5)">
                                            <div class="title">
                                                <span class="step-cizgi-left"></span>
                                                <span class="step-cizgi-right"></span>
                                                <span class="step-icon">5</span>
                                                <span class="step-text">Sorular B</span>
                                                <span class="badge" *ngIf="PageData.Adim5Durum === false">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="">
                                        <a (click)="AdimDegistir(6)">
                                            <div class="title">
                                                <span class="step-cizgi-left"></span>
                                                <span class="step-cizgi-right"></span>
                                                <span class="step-icon">6</span>
                                                <span class="step-text">Sorular C</span>
                                                <span class="badge" *ngIf="PageData.Adim6Durum === false">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="">
                                        <a (click)="AdimDegistir(7)">
                                            <div class="title">
                                                <span class="step-cizgi-left"></span>
                                                <span class="step-cizgi-right"></span>
                                                <span class="step-icon">7</span>
                                                <span class="step-text">Sorular D</span>
                                                <span class="badge" *ngIf="PageData.Adim7Durum === false">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="">
                                        <a (click)="AdimDegistir(8)">
                                            <div class="title">
                                                <span class="step-cizgi-left"></span>
                                                <span class="step-cizgi-right"></span>
                                                <span class="step-icon">8</span>
                                                <span class="step-text">Dosya Yükleme</span>
                                                <span class="badge" *ngIf="PageData.Adim8DurumEbook === false">!</span>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div class="content clearfix AdimliForm">
                                <h3 style="color: rgb(177, 152, 92);">Kategorinizi Seçiniz</h3>
                                <div>
                                    <p>
                                        <strong>Kategorileri görmek için aşağıdakilerden birini
                                            seçin.</strong>
                                    </p>
                                </div>

                                <div id="category_filter" class="dev-category-finder dev-flex-container">
                                    <fieldset class="dev-attribute-set-1 dev-flex-child">
                                        <label class="dev-boolean" style="max-width: none; width: 100%;" *ngFor="let item of Kategoriler; let i = index;">
                                            <i class="far fa-check-circle" style="margin-right: 10px; color: green;"
                                                *ngIf="item.Kategori === PageData.Kategori"></i>
                                            <div class="" style="margin-right: 20px; background-color: white; cursor: default;"
                                                *ngIf="item.Kategori !== PageData.Kategori">&nbsp;</div>
                                            <span>
                                                {{item.Kategori}}<br>
                                                <em [innerHTML]="item.Aciklama | htmlPipe"></em>
                                            </span>
                                        </label>
                                    </fieldset>
                                </div>

                                <div class="dev-category-available" *ngIf="SelectedSubKatData">
                                    <h3 style="padding-bottom: 0px; color: rgb(177, 152, 92);">Alt Kategorinizi Seçiniz
                                    </h3>
                                    <div class="dev-flex-container">
                                        <div class="dev-flex-child">
                                            <div id="category_list">
                                                <table class="tbl-category-selection MarginTop0" style="margin-bottom: 0em;">
                                                    <tbody>
                                                        <tr *ngFor="let item of SelectedSubKatData; let i = index;">

                                                            <td class="dev-choice">
                                                                <i class="far fa-check-circle" style="margin-right: 10px; font-size: 14px; color: green;" *ngIf="item.Kategori === PageData.SubKategori"></i>
                                                                <!-- <i class="far fa-circle" style="margin-right: 10px; font-size: 14px; background-color: darkgray; cursor: default;" *ngIf="item.SubKategori !== PageData.SubKategori"></i> -->
                                                            </td>
                                                            <td>
                                                                <p style="margin-bottom: 0rem;"><span class="dev-cat-name FontWeight500">{{i + 1}} -
                                                                        {{item.Kategori}}</span><br>
                                                                    <em [innerHTML]="item.Aciklama | htmlPipe"></em>
                                                                </p>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="actions clearfix">
                                <ul role="menu" class="Pagination">
                                    <li class="disabled"><a role="menuitem">Bir Önceki Adım</a></li>
                                    <li (click)="AdimDegistir(2)"><a role="menuitem">Bir Sonraki Adım</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </article>
    </div>
</section>