import { Component, OnInit, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHandler, HttpRequest, HttpParams, HttpHeaders } from '@angular/common/http';
import { Globals } from '../providers/globals/globals';
import { Subscription } from 'rxjs/Subscription';
import { MessageServiceService } from '../services/message-service.service';

@Component({
	selector: 'app-editentryadim1',
	templateUrl: './editentryadim1.component.html',
	styleUrls: ['./editentryadim1.component.css']
})
export class EditentryAdim1Component implements OnInit {
	GlobalYonetim: any;
	subscription: Subscription;
	EntryId;
	PageData: any;
	OrgData: any;
	Kategoriler: any;
	SelectedSubKatData: any;
	SelectedKatIndex = -1;
	SelectedSubKatIndex = -1;
	Yuklendi = false;
	Hazir = false;
	IlkSurdurulebilirlik = false;
	SonSurdurulebilirlik = false;
	Yil = '';

	constructor(
		private globals: Globals,
		private http: HttpClient,
		private route: ActivatedRoute,
		private router: Router,
		private toastr: ToastrService,
		private zone: NgZone,
		private messageService: MessageServiceService,
	) {

	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	ngOnInit() {
		this.route.paramMap.subscribe(params => {
			this.EntryId = params.get('EntryId');
		});

		if (this.globals.Yonetim.Yuklendi === true) {
			this.GlobalYonetim = this.globals.YilaGoreGlobalYonetimAl(this.Yil);
			this.Kategoriler = this.globals.YilaGoreKategoriAl(this.Yil);
			window.scrollTo(0, 0);
			this.DataYukle();
		}

		this.subscription = this.messageService.getMessage().subscribe(message => {
			if (message.text === 'Yonetim') {
				this.GlobalYonetim = this.globals.YilaGoreGlobalYonetimAl(this.Yil);
				this.Kategoriler = this.globals.YilaGoreKategoriAl(this.Yil);
				window.scrollTo(0, 0);
				this.DataYukle();
			}
		});
	}

	DataYukle() {
		// console.log(this.Kategoriler);
		let result = this.globals.projeler.filter(function (elem, i, rep) {
			return elem.Id === this;
		}, parseInt(this.EntryId));
		// console.log(result);

		if (result.length) {
			this.PageData = JSON.parse(JSON.stringify(result[0]));
			this.OrgData = JSON.parse(JSON.stringify(result[0]));
			if (this.PageData.EBook_Onay !== 2) {
				this.zone.run(() => {
					this.router.navigateByUrl('/giden');
				});
			}

			this.IlkSurdurulebilirlik = this.PageData['Surdurulebilir'];
			this.SonSurdurulebilirlik = this.PageData['Surdurulebilir'];

			this.Yil = this.PageData['Yil'];
			this.GlobalYonetim = this.globals.YilaGoreGlobalYonetimAl(this.Yil);
			this.Kategoriler = this.globals.YilaGoreKategoriAl(this.Yil);

			let that = this;
			// this.zone.run(() => {
			// 	let KatSay = 0;
			// 	that.globals.Yonetim.Adim1.forEach(element => {
			// 		let SubKatSay = 0;
			// 		if (element['Kategori'] == that.PageData.Kategori) {
			// 			that.SelectedKatIndex = KatSay;
			// 			that.Hazir = true;
			// 			element['SubKategoriler'].forEach(SubElement => {
			// 				if (SubElement['SubKategori'] == that.PageData.SubKategori) {
			// 					that.SelectedSubKatIndex = SubKatSay;
			// 					that.SelectedSubKatData = element['SubKategoriler'];
			// 				}
			// 				SubKatSay++;
			// 			});
			// 		}
			// 		KatSay++;
			// 	});
			// 	that.Yuklendi = true;
			// });
			this.zone.run(() => {
				let KatSay = 0;
				that.Kategoriler.forEach(element => {
					let SubKatSay = 0;
					if (element['Id'] == that.PageData.KategoriId) {
						that.SelectedKatIndex = KatSay;
						element['SubKategoriler'].forEach(SubElement => {
							if (SubElement['Id'] == that.PageData.SubKategoriId) {
								that.SelectedSubKatIndex = SubKatSay;
								that.SelectedSubKatData = element['SubKategoriler'];
							}
							SubKatSay++;
						});
					}
					KatSay++;
				});
				that.Yuklendi = true;
			});
		}
	}

	KategoriChanged(Index) {
		this.SelectedKatIndex = Index;
		this.PageData['Kategori'] = this.globals.Yonetim.Adim1[Index]['Kategori'];
		this.SelectedSubKatData = this.globals.Yonetim.Adim1[Index]['SubKategoriler'];
		this.Hazir = false;
		this.SelectedSubKatIndex = -1;
		this.PageData['SubKategori'] = '';
		this.SonSurdurulebilirlik = false;
		this.PageData.Surdurulebilir = 0;
		if (this.IlkSurdurulebilirlik !== this.SonSurdurulebilirlik) {
			this.showWarning('Adım 3,4,5,6 değişecek');
		}
	}

	SubKategoriChanged(Index) {
		this.SelectedSubKatIndex = Index;
		this.PageData['SubKategori'] = this.globals.Yonetim.Adim1[this.SelectedKatIndex]['SubKategoriler'][Index]['SubKategori'];
		this.SonSurdurulebilirlik = this.globals.Yonetim.Adim1[this.SelectedKatIndex]['SubKategoriler'][Index]['Surdurulebilir'];
		this.PageData['Surdurulebilir'] = this.globals.Yonetim.Adim1[this.SelectedKatIndex]['SubKategoriler'][Index]['Surdurulebilir'];
		this.Hazir = true;
		if (this.IlkSurdurulebilirlik !== this.SonSurdurulebilirlik) {
			this.showWarning('Adım 3,4,5,6 değişecek');
		}
	}

	showSuccess(mesaj: string) {
		this.toastr.success(mesaj, 'Tebrikler!');
	}

	showError(mesaj: string) {
		this.toastr.error(mesaj, 'Hata!');
	}

	showWarning(mesaj: string) {
		this.toastr.warning(mesaj, 'Dikkat!');
	}

	showInfo(mesaj: string) {
		this.toastr.info(mesaj);
	}

	AdimDegistir(sayfa: number) {
		if (typeof sayfa !== 'undefined') {
			if (this.SelectedKatIndex === -1) {
				this.showWarning('Kategori Seçiniz.');
				return;
			}
			if (this.SelectedSubKatIndex === -1) {
				this.showWarning('Alt Kategori Seçiniz.');
				return;
			}

			if (this.PageData['Adim2Durum'] === 0 && sayfa > 2) {
				this.showWarning('Önce ikinci adımı tamamlamalısınız.');
				this.router.navigateByUrl('/editentry/' + this.EntryId + '/2');
				return;
			}

			if (JSON.stringify(this.PageData) !== JSON.stringify(this.OrgData)) {
				let UpdateData;
				let Adim3 = [];
				let Adim4 = [];
				let Adim5 = [];
				let Adim6 = [];

				if (this.IlkSurdurulebilirlik !== this.SonSurdurulebilirlik) {
					if (this.SonSurdurulebilirlik === false) {

						this.GlobalYonetim['Adim3'].forEach(element => {
							let temp;
							if (element.Type === 'Metin') {
								temp = {
									Type: 'Metin'
								};
							}
							if (element.Type === 'SoruText') {
								temp = {
									Type: 'SoruText',
									Cevap: ''
								};
							}
							if (element.Type === 'Kaynak') {
								temp = {
									Type: 'Kaynak',
									Cevap: ''
								};
							}
							if (element.Type === 'Radio') {
								temp = {
									Type: 'Radio',
									Radios: []
								};
								element.Radios.forEach(RadiosElement => {
									let TempTemp = {
										Value: false
									};
									temp.Radios.push(TempTemp);
								});

							}
							if (element.Type === 'CheckBox') {
								temp = {
									Type: 'CheckBox',
									CheckBoxes: []
								};

								element.CheckBoxes.forEach(CheckBoxElement => {
									let TempTemp;
									if (CheckBoxElement.Type === 'CheckBox') {
										TempTemp = {
											Type: 'CheckBox',
											Value: false
										};
									}
									else if (CheckBoxElement.Type === 'CheckBoxText') {
										TempTemp = {
											Type: 'CheckBoxText',
											Value: false,
											Cevap: ''
										};
									}
									temp.CheckBoxes.push(TempTemp);
								});
							}
							if (element.Type === 'MultiCheckBox') {

								temp = {
									Type: 'MultiCheckBox',
									CheckBoxes: []
								};

								element.CheckBoxes.forEach(CheckBoxElement => {
									let TempTemp = {
										SubCheckBoxes: []
									};

									CheckBoxElement.SubCheckBoxes.forEach(SubCheckBoxElement => {
										let TempTempTemp
										if (SubCheckBoxElement.Type === 'CheckBox') {
											TempTempTemp = {
												Type: 'CheckBox',
												Value: false
											};
										}
										else if (SubCheckBoxElement.Type === 'CheckBoxText') {
											TempTempTemp = {
												Type: 'CheckBoxText',
												Value: false,
												Cevap: ''
											};
										}
										TempTemp.SubCheckBoxes.push(TempTempTemp);
									});
									temp.CheckBoxes.push(TempTemp);
								});
							}
							Adim3.push(temp);
						});

						this.GlobalYonetim['Adim4'].forEach(element => {
							let temp;
							if (element.Type === 'Metin') {
								temp = {
									Type: 'Metin'
								};
							}
							if (element.Type === 'SoruText') {
								temp = {
									Type: 'SoruText',
									Cevap: ''
								};
							}
							if (element.Type === 'Kaynak') {
								temp = {
									Type: 'Kaynak',
									Cevap: ''
								};
							}
							if (element.Type === 'Radio') {
								temp = {
									Type: 'Radio',
									Radios: []
								};

								element.Radios.forEach(RadiosElement => {
									let TempTemp = {
										Value: false
									};
									temp.Radios.push(TempTemp);
								});

							}
							if (element.Type === 'CheckBox') {
								temp = {
									Type: 'CheckBox',
									CheckBoxes: []
								};
								element.CheckBoxes.forEach(CheckBoxElement => {
									let TempTemp;
									if (CheckBoxElement.Type === 'CheckBox') {
										TempTemp = {
											Type: 'CheckBox',
											Value: false
										};
									}
									else if (CheckBoxElement.Type === 'CheckBoxText') {
										TempTemp = {
											Type: 'CheckBoxText',
											Value: false,
											Cevap: ''
										};
									}
									temp.CheckBoxes.push(TempTemp);
								});
							}
							if (element.Type === 'MultiCheckBox') {

								temp = {
									Type: 'MultiCheckBox',
									CheckBoxes: []
								};

								element.CheckBoxes.forEach(CheckBoxElement => {
									let TempTemp = {
										SubCheckBoxes: []
									};

									CheckBoxElement.SubCheckBoxes.forEach(SubCheckBoxElement => {
										let TempTempTemp
										if (SubCheckBoxElement.Type === 'CheckBox') {
											TempTempTemp = {
												Type: 'CheckBox',
												Value: false
											};
										}
										else if (SubCheckBoxElement.Type === 'CheckBoxText') {
											TempTempTemp = {
												Type: 'CheckBoxText',
												Value: false,
												Cevap: ''
											};
										}
										TempTemp.SubCheckBoxes.push(TempTempTemp);
									});
									temp.CheckBoxes.push(TempTemp);
								});
							}
							Adim4.push(temp);
						});

						this.GlobalYonetim['Adim5'].forEach(element => {
							let temp;
							if (element.Type === 'Metin') {
								temp = {
									Type: 'Metin'
								};
							}
							if (element.Type === 'SoruText') {
								temp = {
									Type: 'SoruText',
									Cevap: ''
								};
							}
							if (element.Type === 'Kaynak') {
								temp = {
									Type: 'Kaynak',
									Cevap: ''
								};
							}
							if (element.Type === 'Radio') {
								temp = {
									Type: 'Radio',
									Radios: []
								};

								element.Radios.forEach(RadiosElement => {
									let TempTemp = {
										Value: false
									};
									temp.Radios.push(TempTemp);
								});

							}
							if (element.Type === 'CheckBox') {
								temp = {
									Type: 'CheckBox',
									CheckBoxes: []
								};

								element.CheckBoxes.forEach(CheckBoxElement => {
									let TempTemp;
									if (CheckBoxElement.Type === 'CheckBox') {
										TempTemp = {
											Type: 'CheckBox',
											Value: false
										};
									}
									else if (CheckBoxElement.Type === 'CheckBoxText') {
										TempTemp = {
											Type: 'CheckBoxText',
											Value: false,
											Cevap: ''
										};
									}
									temp.CheckBoxes.push(TempTemp)
								});
							}
							if (element.Type === 'MultiCheckBox') {

								temp = {
									Type: 'MultiCheckBox',
									CheckBoxes: []
								};

								element.CheckBoxes.forEach(CheckBoxElement => {
									let TempTemp = {
										SubCheckBoxes: []
									};
									CheckBoxElement.SubCheckBoxes.forEach(SubCheckBoxElement => {
										let TempTempTemp
										if (SubCheckBoxElement.Type === 'CheckBox') {
											TempTempTemp = {
												Type: 'CheckBox',
												Value: false
											};
										}
										else if (SubCheckBoxElement.Type === 'CheckBoxText') {
											TempTempTemp = {
												Type: 'CheckBoxText',
												Value: false,
												Cevap: ''
											};
										}
										TempTemp.SubCheckBoxes.push(TempTempTemp);
									});
									temp.CheckBoxes.push(TempTemp);
								});
							}
							Adim5.push(temp);
						});

						this.GlobalYonetim['Adim6'].forEach(element => {
							let temp;
							if (element.Type === 'Metin') {
								temp = {
									Type: 'Metin'
								};
							}
							if (element.Type === 'SoruText') {
								temp = {
									Type: 'SoruText',
									Cevap: ''
								};
							}
							if (element.Type === 'Kaynak') {
								temp = {
									Type: 'Kaynak',
									Cevap: ''
								};
							}
							if (element.Type === 'Radio') {
								temp = {
									Type: 'Radio',
									Radios: []
								};
								element.Radios.forEach(RadiosElement => {
									let TempTemp = {
										Value: false
									};
									temp.Radios.push(TempTemp);
								});

							}
							if (element.Type === 'CheckBox') {
								temp = {
									Type: 'CheckBox',
									CheckBoxes: []
								};

								element.CheckBoxes.forEach(CheckBoxElement => {
									let TempTemp;
									if (CheckBoxElement.Type === 'CheckBox') {
										TempTemp = {
											Type: 'CheckBox',
											Value: false
										};
									}
									else if (CheckBoxElement.Type === 'CheckBoxText') {
										TempTemp = {
											Type: 'CheckBoxText',
											Value: false,
											Cevap: ''
										};
									}
									temp.CheckBoxes.push(TempTemp);
								});
							}
							if (element.Type === 'MultiCheckBox') {

								temp = {
									Type: 'MultiCheckBox',
									CheckBoxes: []
								};

								element.CheckBoxes.forEach(CheckBoxElement => {
									let TempTemp = {
										SubCheckBoxes: []
									};

									CheckBoxElement.SubCheckBoxes.forEach(SubCheckBoxElement => {
										let TempTempTemp;
										if (SubCheckBoxElement.Type === 'CheckBox') {
											TempTempTemp = {
												Type: 'CheckBox',
												Value: false
											}
										}
										else if (SubCheckBoxElement.Type === 'CheckBoxText') {
											TempTempTemp = {
												Type: 'CheckBoxText',
												Value: false,
												Cevap: ''
											};
										}
										TempTemp.SubCheckBoxes.push(TempTempTemp);
									});
									temp.CheckBoxes.push(TempTemp);
								});
							}
							Adim6.push(temp);
						});
					}
					else {

						this.GlobalYonetim['Adim3Surdurulebilir'].forEach(element => {
							let temp;
							if (element.Type === 'Metin') {
								temp = {
									Type: 'Metin'
								};
							}
							if (element.Type === 'SoruText') {
								temp = {
									Type: 'SoruText',
									Cevap: ''
								};
							}
							if (element.Type === 'Kaynak') {
								temp = {
									Type: 'Kaynak',
									Cevap: ''
								};
							}
							if (element.Type === 'Radio') {
								temp = {
									Type: 'Radio',
									Radios: []
								};

								element.Radios.forEach(RadiosElement => {
									let TempTemp = {
										Value: false
									};
									temp.Radios.push(TempTemp)
								});

							}
							if (element.Type === 'CheckBox') {
								temp = {
									Type: 'CheckBox',
									CheckBoxes: []
								};

								element.CheckBoxes.forEach(CheckBoxElement => {
									let TempTemp;
									if (CheckBoxElement.Type === 'CheckBox') {
										TempTemp = {
											Type: 'CheckBox',
											Value: false
										};
									}
									else if (CheckBoxElement.Type === 'CheckBoxText') {
										TempTemp = {
											Type: 'CheckBoxText',
											Value: false,
											Cevap: ''
										};
									}
									temp.CheckBoxes.push(TempTemp);
								});
							}
							if (element.Type === 'MultiCheckBox') {

								temp = {
									Type: 'MultiCheckBox',
									CheckBoxes: []
								};

								element.CheckBoxes.forEach(CheckBoxElement => {
									let TempTemp = {
										SubCheckBoxes: []
									};

									CheckBoxElement.SubCheckBoxes.forEach(SubCheckBoxElement => {
										let TempTempTemp;
										if (SubCheckBoxElement.Type === 'CheckBox') {
											TempTempTemp = {
												Type: 'CheckBox',
												Value: false
											};
										}
										else if (SubCheckBoxElement.Type === 'CheckBoxText') {
											TempTempTemp = {
												Type: 'CheckBoxText',
												Value: false,
												Cevap: ''
											};
										}
										TempTemp.SubCheckBoxes.push(TempTempTemp);
									});
									temp.CheckBoxes.push(TempTemp);
								});
							}
							Adim3.push(temp);
						});

						this.GlobalYonetim['Adim4Surdurulebilir'].forEach(element => {
							let temp;
							if (element.Type === 'Metin') {
								temp = {
									Type: 'Metin'
								};
							}
							if (element.Type === 'SoruText') {
								temp = {
									Type: 'SoruText',
									Cevap: ''
								};
							}
							if (element.Type === 'Kaynak') {
								temp = {
									Type: 'Kaynak',
									Cevap: ''
								};
							}
							if (element.Type === 'Radio') {
								temp = {
									Type: 'Radio',
									Radios: []
								};

								element.Radios.forEach(RadiosElement => {
									let TempTemp = {
										Value: false
									}
									temp.Radios.push(TempTemp)
								});

							}
							if (element.Type === 'CheckBox') {
								temp = {
									Type: 'CheckBox',
									CheckBoxes: []
								};

								element.CheckBoxes.forEach(CheckBoxElement => {
									let TempTemp
									if (CheckBoxElement.Type === 'CheckBox') {
										TempTemp = {
											Type: 'CheckBox',
											Value: false
										};
									}
									else if (CheckBoxElement.Type === 'CheckBoxText') {
										TempTemp = {
											Type: 'CheckBoxText',
											Value: false,
											Cevap: ''
										};
									}
									temp.CheckBoxes.push(TempTemp);
								});
							}
							if (element.Type === 'MultiCheckBox') {

								temp = {
									Type: 'MultiCheckBox',
									CheckBoxes: []
								};

								element.CheckBoxes.forEach(CheckBoxElement => {
									let TempTemp = {
										SubCheckBoxes: []
									};

									CheckBoxElement.SubCheckBoxes.forEach(SubCheckBoxElement => {
										let TempTempTemp;
										if (SubCheckBoxElement.Type === 'CheckBox') {
											TempTempTemp = {
												Type: 'CheckBox',
												Value: false
											};
										}
										else if (SubCheckBoxElement.Type === 'CheckBoxText') {
											TempTempTemp = {
												Type: 'CheckBoxText',
												Value: false,
												Cevap: ''
											};
										}
										TempTemp.SubCheckBoxes.push(TempTempTemp);
									});
									temp.CheckBoxes.push(TempTemp);
								});
							}
							Adim4.push(temp);
						});

						this.GlobalYonetim['Adim5Surdurulebilir'].forEach(element => {
							let temp
							if (element.Type === 'Metin') {
								temp = {
									Type: 'Metin'
								};
							}
							if (element.Type === 'SoruText') {
								temp = {
									Type: 'SoruText',
									Cevap: ''
								};
							}
							if (element.Type === 'Kaynak') {
								temp = {
									Type: 'Kaynak',
									Cevap: ''
								};
							}
							if (element.Type === 'Radio') {
								temp = {
									Type: 'Radio',
									Radios: []
								};

								element.Radios.forEach(RadiosElement => {
									let TempTemp = {
										Value: false
									};
									temp.Radios.push(TempTemp);
								});

							}
							if (element.Type === 'CheckBox') {
								temp = {
									Type: 'CheckBox',
									CheckBoxes: []
								};

								element.CheckBoxes.forEach(CheckBoxElement => {
									let TempTemp;
									if (CheckBoxElement.Type === 'CheckBox') {
										TempTemp = {
											Type: 'CheckBox',
											Value: false
										};
									}
									else if (CheckBoxElement.Type === 'CheckBoxText') {
										TempTemp = {
											Type: 'CheckBoxText',
											Value: false,
											Cevap: ''
										};
									}
									temp.CheckBoxes.push(TempTemp);
								});
							}
							if (element.Type === 'MultiCheckBox') {

								temp = {
									Type: 'MultiCheckBox',
									CheckBoxes: []
								};

								element.CheckBoxes.forEach(CheckBoxElement => {
									let TempTemp = {
										SubCheckBoxes: []
									};

									CheckBoxElement.SubCheckBoxes.forEach(SubCheckBoxElement => {
										let TempTempTemp
										if (SubCheckBoxElement.Type === 'CheckBox') {
											TempTempTemp = {
												Type: 'CheckBox',
												Value: false
											};
										}
										else if (SubCheckBoxElement.Type === 'CheckBoxText') {
											TempTempTemp = {
												Type: 'CheckBoxText',
												Value: false,
												Cevap: ''
											};
										}
										TempTemp.SubCheckBoxes.push(TempTempTemp);
									});
									temp.CheckBoxes.push(TempTemp);
								});
							}
							Adim5.push(temp);
						});

						this.GlobalYonetim['Adim6Surdurulebilir'].forEach(element => {
							let temp
							if (element.Type === 'Metin') {
								temp = {
									Type: 'Metin'
								};
							}
							if (element.Type === 'SoruText') {
								temp = {
									Type: 'SoruText',
									Cevap: ''
								};
							}
							if (element.Type === 'Kaynak') {
								temp = {
									Type: 'Kaynak',
									Cevap: ''
								};
							}
							if (element.Type === 'Radio') {
								temp = {
									Type: 'Radio',
									Radios: []
								};

								element.Radios.forEach(RadiosElement => {
									let TempTemp = {
										Value: false
									};
									temp.Radios.push(TempTemp);
								});

							}
							if (element.Type === 'CheckBox') {
								temp = {
									Type: 'CheckBox',
									CheckBoxes: []
								};

								element.CheckBoxes.forEach(CheckBoxElement => {
									let TempTemp;
									if (CheckBoxElement.Type === 'CheckBox') {
										TempTemp = {
											Type: 'CheckBox',
											Value: false
										};
									}
									else if (CheckBoxElement.Type === 'CheckBoxText') {
										TempTemp = {
											Type: 'CheckBoxText',
											Value: false,
											Cevap: ''
										};
									}
									temp.CheckBoxes.push(TempTemp);
								});
							}
							if (element.Type === 'MultiCheckBox') {

								temp = {
									Type: 'MultiCheckBox',
									CheckBoxes: []
								};

								element.CheckBoxes.forEach(CheckBoxElement => {
									let TempTemp = {
										SubCheckBoxes: []
									};

									CheckBoxElement.SubCheckBoxes.forEach(SubCheckBoxElement => {
										let TempTempTemp;
										if (SubCheckBoxElement.Type === 'CheckBox') {
											TempTempTemp = {
												Type: 'CheckBox',
												Value: false
											};
										}
										else if (SubCheckBoxElement.Type === 'CheckBoxText') {
											TempTempTemp = {
												Type: 'CheckBoxText',
												Value: false,
												Cevap: ''
											};
										}
										TempTemp.SubCheckBoxes.push(TempTempTemp);
									});
									temp.CheckBoxes.push(TempTemp);
								});
							}
							Adim6.push(temp)
						});
					}

					UpdateData = {
						'Adim3': Adim3,
						'Adim3Durum': false,
						'Adim4': Adim4,
						'Adim4Durum': false,
						'Adim5': Adim5,
						'Adim5Durum': false,
						'Adim6': Adim6,
						'Adim6Durum': false,
						'Adim8DurumEbook': false,
						'Adim8': {
							'SayfaSayisi': 0,
							'Tarih': new Date()
						},
						'Kategori': this.PageData['Kategori'],
						'SubKategori': this.PageData['SubKategori'],
						'Surdurulebilir': this.PageData['Surdurulebilir'],
						'GuncellemeTarihi': new Date()
					}
				}
				else {
					UpdateData = {
						'Adim8DurumEbook': false,
						'Kategori': this.PageData['Kategori'],
						'SubKategori': this.PageData['SubKategori'],
						'Surdurulebilir': this.PageData['Surdurulebilir'],
						'GuncellemeTarihi': new Date()
					}
				}

				UpdateData.Id = this.EntryId;
				// console.log(JSON.stringify(UpdateData));

				const params = new HttpParams();
				let headers = new HttpHeaders();
				headers = headers.append('Accept', 'application/json, text/javascript, */*; q=0.01');
				headers = headers.append('Content-Type', 'application/json');
				headers = headers.append('Authorization', 'Bearer ' + this.globals.User.Token);

				this.http.post('https://api.effieturkiye.org/ProjeEbook', UpdateData, { params, headers }).subscribe(
					(response) => {
						const index = this.globals.projeler.findIndex(elem => elem.Id === Number(this.EntryId));
						this.globals.projeler[index] = response;
						this.showSuccess("İlk adım kaydedildi, yönlendiriliyorsunuz.");
						this.messageService.sendMessage('ProjeGuncellendi');
						this.router.navigateByUrl('/editentry/' + this.EntryId + '/' + sayfa);
					},
					error => {
						//  console.log('Error', error)
						this.showError('Bir hata oluştu, Lütfen tekrar deneyiniz');
					}
				);
			}
			else {
				this.router.navigateByUrl('/editentry/' + this.EntryId + '/' + sayfa);
			}
		}
		else {
			this.showError('Bir hata oluştu.');
		}
	}

}
