<section id="IcerikContainer">
    <div class="dev-wrapper dev-entrant-home dev-wrapper-entry-home dev-flex-container IcerikSubContainer">
        <article id="content" class="dev-flex-child">
            <div class="wizard-v4-content">
                <div class="wizard-form">
                    <div class="wizard-header">
                        <h3 class="heading">Başvuru Güncelleme</h3>
                        <!-- <p>Formu doldurup bir sonraki adıma geçiniz, veya istediğiniz adıma geçebilirsiniz.</p> -->
                    </div>
                    <div class="form-register gonderilecek" action="#" method="post" *ngIf="Yuklendi === true && PageData.EBook_Onay === 2">
                        <div id="form-total" role="application" class="wizard clearfix">
                            <div class="steps clearfix">
                                <ul role="tablist">
                                    <li role="tab" class="done">
                                        <a (click)="AdimDegistir(1)">
                                            <div class="title">
                                                <span class="step-cizgi-left renkli"></span>
                                                <span class="step-cizgi-right renkli"></span>
                                                <span class="step-icon">1</span>
                                                <span class="step-text">Kategori Seçimi</span>
                                                <span class="badge" *ngIf="PageData.Adim1Durum === false">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="done">
                                        <a (click)="AdimDegistir(2)">
                                            <div class="title">
                                                <span class="step-cizgi-left renkli"></span>
                                                <span class="step-cizgi-right renkli"></span>
                                                <span class="step-icon">2</span>
                                                <span class="step-text">Künye</span>
                                                <span class="badge" *ngIf="PageData.Adim2Durum === false">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="done">
                                        <a (click)="AdimDegistir(3)">
                                            <div class="title">
                                                <span class="step-cizgi-left renkli"></span>
                                                <span class="step-cizgi-right"></span>
                                                <span class="step-icon">3</span>
                                                <span class="step-text">Özet</span>
                                                <span class="badge" *ngIf="PageData.Adim3Durum === false">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="done">
                                        <a (click)="AdimDegistir(4)">
                                            <div class="title">
                                                <span class="step-cizgi-left renkli"></span>
                                                <span class="step-cizgi-right renkli"></span>
                                                <span class="step-icon">4</span>
                                                <span class="step-text">Sorular A</span>
                                                <span class="badge" *ngIf="PageData.Adim4Durum === false">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="done">
                                        <a (click)="AdimDegistir(5)">
                                            <div class="title">
                                                <span class="step-cizgi-left renkli"></span>
                                                <span class="step-cizgi-right renkli"></span>
                                                <span class="step-icon">5</span>
                                                <span class="step-text">Sorular B</span>
                                                <span class="badge" *ngIf="PageData.Adim5Durum === false">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="done">
                                        <a (click)="AdimDegistir(6)">
                                            <div class="title">
                                                <span class="step-cizgi-left renkli"></span>
                                                <span class="step-cizgi-right renkli"></span>
                                                <span class="step-icon">6</span>
                                                <span class="step-text">Sorular C</span>
                                                <span class="badge" *ngIf="PageData.Adim6Durum === false">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="done">
                                        <a (click)="AdimDegistir(7)">
                                            <div class="title">
                                                <span class="step-cizgi-left renkli"></span>
                                                <span class="step-cizgi-right renkli"></span>
                                                <span class="step-icon">7</span>
                                                <span class="step-text">Sorular D</span>
                                                <span class="badge" *ngIf="PageData.Adim7Durum === false">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="done">
                                        <a (click)="AdimDegistir(8)">
                                            <div class="title">
                                                <span class="step-cizgi-left renkli"></span>
                                                <span class="step-cizgi-right renkli"></span>
                                                <span class="step-icon">8</span>
                                                <span class="step-text">Dosya Yükleme</span>
                                                <span class="badge" *ngIf="PageData.Adim8DurumEbook === false">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="done">
                                        <a>
                                            <div class="title">
                                                <span class="step-cizgi-left renkli"></span>
                                                <span class="step-cizgi-right"></span>
                                                <span class="step-icon">9</span>
                                                <span class="step-text">Ajansa Gönder</span>

                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div class="content clearfix">
                                <form class="form form-horizontal AdimliForm" novalidate="">
                                    <!-- <p *ngIf="GlobalYonetim.Adim10.Header.length > 0" [innerHtml]="GlobalYonetim.Adim10.Header" style="max-width: none; width: 100%; margin-bottom: 10px"></p> -->


                                    <ng-container>
                                        <!-- Son Güncelleme Tarihi: {{PageData.GuncellemeTarihi | date:'dd.MM.yyyy HH:mm'}} -->
                                        <br> Son PDF Güncelleme Tarihi: {{PageData.Adim9TarihEbook | date:'dd.MM.yyyy HH:mm'}} 
                                        <!-- <br> Sayfa Sayısı: {{PageData.Adim9SayfaSayisi}} -->
                                        <br>
                                        <!-- Sayfa Sayısı: {{PageData["Adim8"].SayfaSayisi}}<br> -->
                                        <a href="https://pdfebook.effieturkiye.org/pdfler/{{PageData.Id}}/4.pdf" target="_blank">Son Versiyonu İndİr</a>
                                    </ng-container>
                                    <br><br>
                                    <button class="EkleButton" [ladda]="isLoading" *ngIf="PageData.Adim1Durum === 1 && PageData.Adim2Durum === 1 && PageData.Adim4Durum === 1 && PageData.Adim5Durum === 1 && PageData.Adim6Durum === 1 && PageData.Adim7Durum === 1 && PageData.Adim8DurumEbook === 1"
                                        (click)="HtmlOlustur()">PDF Oluştur</button>
                                    <br><br>
                                    <!-- <p class="kirmizi" *ngIf="PageData.Adim1Durum === false || PageData.Adim2Durum === false || PageData.Adim3Durum === false || PageData.Adim4Durum === false || PageData.Adim5Durum === false || PageData.Adim6Durum === false || PageData.Adim7Durum === false">
                                        Başvuruyu gönderebilmeniz için Bütün adımları tamamlamalısınız
                                    </p> -->

                                    <button *ngIf="PageData.Adim1Durum === 1 && PageData.Adim2Durum === 1 && PageData.Adim4Durum === 1 && PageData.Adim5Durum === 1 && PageData.Adim6Durum === 1 && PageData.Adim7Durum === 1 && PageData.Adim8DurumEbook === 1" class="EkleButton" [swal]="DosyaSilSwal"
                                        (confirm)="BasvuruGonder()">Ajansa
                                        Gönder</button>
                                    <!-- <button 
                                        class="EkleButton" [swal]="DosyaSilSwal" (confirm)="BasvuruGonder()">Ajansa Gönder</button> -->

                                </form>
                            </div>
                            <div class="actions clearfix">
                                <ul role="menu" class="Pagination">
                                    <li (click)="AdimDegistir(9)"><a role="menuitem">Bir Önceki Adım</a></li>
                                    <!-- <li (click)="AdimDegistir(9)"><a role="menuitem">Bir Sonraki Adım</a></li> -->
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </article>
    </div>

</section>