import { Component, OnInit, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHandler, HttpRequest, HttpParams, HttpHeaders } from '@angular/common/http';
import { Globals } from '../providers/globals/globals';
import { Subscription } from 'rxjs/Subscription';
import { MessageServiceService } from '../services/message-service.service';

@Component({
	selector: 'app-viewentryadim1',
	templateUrl: './viewentryadim1.component.html',
	styleUrls: ['./viewentryadim1.component.css']
})
export class ViewentryAdim1Component implements OnInit {
	GlobalYonetim: any;
	subscription: Subscription;
	EntryId;
	PageData: any;
	OrgData: any;
	Kategoriler: any;
	SelectedSubKatData: any;
	SelectedKatIndex = -1;
	SelectedSubKatIndex = -1;
	Yuklendi = false;
	Hazir = false;
	IlkSurdurulebilirlik = false;
	SonSurdurulebilirlik = false;
	Yil = '';

	constructor(
		private globals: Globals,
		private http: HttpClient,
		private route: ActivatedRoute,
		private router: Router,
		private toastr: ToastrService,
		private zone: NgZone,
		private messageService: MessageServiceService,
	) {

	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	ngOnInit() {
		this.route.paramMap.subscribe(params => {
			this.EntryId = params.get('EntryId');
		});

		if (this.globals.Yonetim.Yuklendi === true) {
			this.GlobalYonetim = this.globals.YilaGoreGlobalYonetimAl(this.Yil);
			this.Kategoriler = this.globals.YilaGoreKategoriAl(this.Yil);
			window.scrollTo(0, 0);
			this.DataYukle();
		}

		this.subscription = this.messageService.getMessage().subscribe(message => {
			if (message.text === 'Yonetim') {
				this.GlobalYonetim = this.globals.YilaGoreGlobalYonetimAl(this.Yil);
				this.Kategoriler = this.globals.YilaGoreKategoriAl(this.Yil);
				window.scrollTo(0, 0);
				this.DataYukle();
			}
		});
	}

	DataYukle() {
		let result = this.globals.projeler.filter(function (elem, i, rep) {
			return elem.Id === this;
		}, parseInt(this.EntryId));
		// console.log(result);

		if (result.length) {
			this.PageData = JSON.parse(JSON.stringify(result[0]));
			this.OrgData = JSON.parse(JSON.stringify(result[0]));
			if (this.PageData.EBook_Onay !== 3) {
				this.zone.run(() => {
					this.router.navigateByUrl('/giden');
				});
			}
			this.Yil = this.PageData['Yil'];
			this.GlobalYonetim = this.globals.YilaGoreGlobalYonetimAl(this.Yil);
			this.Kategoriler = this.globals.YilaGoreKategoriAl(this.Yil);

			let that = this;
			this.zone.run(() => {
				let KatSay = 0;
				that.Kategoriler.forEach(element => {
					let SubKatSay = 0;
					if (element['Id'] == that.PageData.KategoriId) {
						that.SelectedKatIndex = KatSay;
						element['SubKategoriler'].forEach(SubElement => {
							// console.log(SubElement['Id'])
							// console.log(that.PageData.SubKategoriId)
							if (SubElement['Id'] == that.PageData.SubKategoriId) {
								that.SelectedSubKatIndex = SubKatSay;
								that.SelectedSubKatData = element['SubKategoriler'];
							}
							SubKatSay++;
						});
					}
					KatSay++;
				});
				that.Yuklendi = true;
			});
		}
	}

	showSuccess(mesaj: string) {
		this.toastr.success(mesaj, 'Tebrikler!');
	}

	showError(mesaj: string) {
		this.toastr.error(mesaj, 'Hata!');
	}

	showWarning(mesaj: string) {
		this.toastr.warning(mesaj, 'Dikkat!');
	}

	showInfo(mesaj: string) {
		this.toastr.info(mesaj);
	}

	AdimDegistir(sayfa: number) {
		if (typeof sayfa !== 'undefined') {
			this.router.navigateByUrl('/viewentry/' + this.EntryId + '/' + sayfa);
		}
		else {
			this.showError('Bir hata oluştu.');
		}
	}

}
