<section id="IcerikContainer">
    <div class="dev-wrapper dev-entrant-home dev-wrapper-entry-home dev-flex-container IcerikSubContainer">
        <article id="content" class="dev-flex-child">
            <div class="wizard-v4-content">
                <div class="wizard-form">
                    <div class="wizard-header">
                        <h3 class="heading">Başvuru Güncelleme</h3>
                        <!-- <p>Formu doldurup bir sonraki adıma geçiniz, veya istediğiniz adıma geçebilirsiniz.</p> -->
                    </div>
                    <div class="form-register view" action="#" method="post" *ngIf="Yuklendi === true && PageData.EBook_Onay === 3">
                        <div id="form-total" role="application" class="wizard clearfix">
                            <div class="steps clearfix">
                                <ul role="tablist">
                                    <li role="tab" class="done">
                                        <a (click)="AdimDegistir(1)">
                                            <div class="title">
                                                <span class="step-cizgi-left renkli"></span>
                                                <span class="step-cizgi-right renkli"></span>
                                                <span class="step-icon">1</span>
                                                <span class="step-text">Kategori Seçimi</span>
                                                <span class="badge" *ngIf="PageData.Adim1Durum === false">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="done">
                                        <a>
                                            <div class="title">
                                                <span class="step-cizgi-left renkli"></span>
                                                <span class="step-cizgi-right"></span>
                                                <span class="step-icon">2</span>
                                                <span class="step-text">Künye</span>
                                                <span class="badge" *ngIf="PageData.Adim2Durum === false">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="">
                                        <a (click)="AdimDegistir(3)">
                                            <div class="title">
                                                <span class="step-cizgi-left"></span>
                                                <span class="step-cizgi-right"></span>
                                                <span class="step-icon">3</span>
                                                <span class="step-text">Özet</span>
                                                <span class="badge" *ngIf="PageData.Adim3Durum === false">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="">
                                        <a (click)="AdimDegistir(4)">
                                            <div class="title">
                                                <span class="step-cizgi-left"></span>
                                                <span class="step-cizgi-right"></span>
                                                <span class="step-icon">4</span>
                                                <span class="step-text">Sorular A</span>
                                                <span class="badge" *ngIf="PageData.Adim4Durum === false">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="">
                                        <a (click)="AdimDegistir(5)">
                                            <div class="title">
                                                <span class="step-cizgi-left"></span>
                                                <span class="step-cizgi-right"></span>
                                                <span class="step-icon">5</span>
                                                <span class="step-text">Sorular B</span>
                                                <span class="badge" *ngIf="PageData.Adim5Durum === false">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="">
                                        <a (click)="AdimDegistir(6)">
                                            <div class="title">
                                                <span class="step-cizgi-left"></span>
                                                <span class="step-cizgi-right"></span>
                                                <span class="step-icon">6</span>
                                                <span class="step-text">Sorular C</span>
                                                <span class="badge" *ngIf="PageData.Adim6Durum === false">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="">
                                        <a (click)="AdimDegistir(7)">
                                            <div class="title">
                                                <span class="step-cizgi-left"></span>
                                                <span class="step-cizgi-right"></span>
                                                <span class="step-icon">7</span>
                                                <span class="step-text">Sorular D</span>
                                                <span class="badge" *ngIf="PageData.Adim7Durum === false">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="">
                                        <a (click)="AdimDegistir(8)">
                                            <div class="title">
                                                <span class="step-cizgi-left"></span>
                                                <span class="step-cizgi-right"></span>
                                                <span class="step-icon">8</span>
                                                <span class="step-text">Dosya Yükleme</span>
                                                <span class="badge" *ngIf="PageData.Adim8DurumEbook === false">!</span>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div class="content clearfix">

                                <form class="form form-horizontal AdimliForm" novalidate="">
                                    <p *ngIf="GlobalYonetim.Adim2.Header.length > 0" [innerHtml]="GlobalYonetim.Adim2.Header" style="max-width: none; width: 100%; margin-bottom: 10px"></p>

                                    <div class="form-body">
                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-3 label-control" style="color: white;" for="MarkaAdi">
                                                <span class="VerticalAlignSub">Logo:</span>
                                                <!-- <span class="gerekli"> *</span> -->
                                            </label>
                                            <div class="col-md-9">
                                                <img *ngIf="PageData.Logo" src="https://api.effieturkiye.org/storage/logolar/{{PageData.Logo}}" style="max-width: 200px; max-height: 70px;">
                                            </div>
                                        </div>

                                        <!-- <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-3 label-control" style="color: white;" for="Ozet">
                                                <span class="VerticalAlignSub">Özet:</span>
                                            </label>
                                            <div class="col-md-9">
                                                <quill-editor disabled="true" [styles]="{height: '250px', background:'white'}" [modules]="{
                                                    toolbar: [
                                                        ['bold', 'italic', 'underline', 'strike'],
                                                        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                                                        [{ 'script': 'sub'}, { 'script': 'super' }]
                                                    ]
                                                    }" placeholder="Açıklama giriniz..." name="Ozet" [(ngModel)]="PageData.Ozet"></quill-editor>

                                            </div>
                                        </div> -->

                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-3 label-control" style="color: white;" for="MarkaAdi">
                                                <span class="VerticalAlignSub">Marka Adı:</span>
                                                <span class="gerekli"> *</span>
                                            </label>
                                            <div class="col-md-9">
                                                <input disabled="true" class="form-control" type="text" placeholder="Marka Adı" name="MarkaAdi" [(ngModel)]="PageData.MarkaAdi">
                                            </div>
                                        </div>
                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-3 label-control" style="color: white;" for="KampanyaAdi">
                                                <span class="VerticalAlignSub">Kampanya Adı:</span>
                                                <span class="gerekli"> *</span>
                                            </label>
                                            <div class="col-md-9">
                                                <input disabled="true" class="form-control" type="text" placeholder="Kampanya Adı" name="KampanyaAdi" [(ngModel)]="PageData.KampanyaAdi">
                                            </div>
                                        </div>
                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-3 label-control" style="color: white;" for="projectinput1">
                                                <span class="VerticalAlignSub">Kategori:</span>
                                            </label>
                                            <div class="col-md-9">
                                                <select disabled="true" class="form-control" name="Kategori" [(ngModel)]="PageData.Kategori">
                                                    <option *ngFor="let c of Kategoriler"
                                                        [ngValue]="c.Kategori">
                                                        {{c.Kategori}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-3 label-control" style="color: white;" for="projectinput1">
                                                <span class="VerticalAlignSub">Alt Kategori:</span>
                                            </label>
                                            <div class="col-md-9">
                                                <select disabled="true" class="form-control" name="SubKategori" [(ngModel)]="PageData.SubKategori">
                                                    <option *ngFor="let c of TempSubKategoriler"
                                                        [ngValue]="c.Kategori">{{c.Kategori}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div *ngIf="IlkSurdurulebilirlik != SonSurdurulebilirlik" style="color: red; font-size: 14px; font-weight: 600;">
                                            Adım 3,4,5,6 değişecek
                                        </div>
                                    </div>


                                    <div class="form-body">
                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-12 label-control" style="color: white;">
                                                <span class="VerticalAlignSub">Reklamveren:</span>
                                            </label>
                                        </div>
                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-3 label-control" style="color: white;" for="ReklamverenAdi">
                                                <span class="VerticalAlignSub">Reklamveren Adı:</span>
                                                <span class="gerekli"> *</span>
                                            </label>
                                            <div class="col-md-9">
                                                <input disabled="true" class="form-control" type="text" placeholder="Reklamverenin Adı" name="ReklamverenAdi" [(ngModel)]="PageData.ReklamverenAdi">
                                            </div>
                                        </div>
                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-3 label-control" style="color: white;" for="ReklamverenAdres">
                                                <span class="VerticalAlignSub">Adresi:</span>
                                                <span class="gerekli"> *</span>
                                            </label>
                                            <div class="col-md-9">
                                                <textarea disabled="true" class="form-control" placeholder="Reklamverenin Adres bilgileri" name="ReklamverenAdres" [(ngModel)]="PageData.ReklamverenAdres"></textarea>
                                            </div>
                                        </div>
                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-3 label-control" style="color: white;" for="ReklamverenHolding">
                                                <span class="VerticalAlignSub">Holding:</span>
                                            </label>
                                            <div class="col-md-9">
                                                <input disabled="true" class="form-control" type="text" placeholder="Holding" name="ReklamverenHolding" [(ngModel)]="PageData.ReklamverenHolding">
                                            </div>
                                        </div>
                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-3 label-control" style="color: white;" for="ReklamverenWebSitesi">
                                                <span class="VerticalAlignSub">Web Sitesi:</span>
                                            </label>
                                            <div class="col-md-9">
                                                <input disabled="true" class="form-control" type="text" placeholder="Web Sitesi" name="ReklamverenWebSitesi" [(ngModel)]="PageData.ReklamverenWebSitesi">
                                            </div>
                                        </div>
                                        <div class="form-group row NoMarginLeftRight" *ngFor="let item of PageData.ReklamverenYetkililer; let i = index;">
                                            <label class="col-md-3 label-control" style="color: white;" for="ReklamverenYetkililer{{i}}">
                                                <span class="VerticalAlignSub">Yetkili {{i + 1}}:</span>
                                                <span class="gerekli" *ngIf="i == 0"> *</span>
                                                <img src="../../assets/eksi.png" class="arti">
                                            </label>
                                            <div class="col-md-9">
                                                <input disabled="true" class="form-control" type="text" placeholder="Ahmet Çetin, Gen. Md. (Pazarlama Md., Reklam Md., Ürün Md., vb. )" name="ReklamverenYetkililer{{i}}Adi" [(ngModel)]="PageData.ReklamverenYetkililer[i]['AdSoyad']">
                                                <input disabled="true" class="form-control" type="text" placeholder="ornek@effie.org" name="ReklamverenYetkililer{{i}}Eposta" [(ngModel)]="PageData.ReklamverenYetkililer[i]['Eposta']">
                                            </div>
                                        </div>
                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-12 label-control" style="color: white;" style="text-align: center;">
                                                <button class="EkleButton">Yetkili Ekle <img
                                                        src="../../assets/arti.png" class="arti"
                                                        style="right: initial;" /></button>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="form-body">
                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-12 label-control" style="color: white;" for="projectinput1">
                                                <span class="VerticalAlignSub">Başvuran Ana Ajans:</span>
                                            </label>
                                        </div>
                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-3 label-control" style="color: white;" for="BasvuranAjansAdi">
                                                <span class="VerticalAlignSub">Adı:</span>
                                                <span class="gerekli"> *</span>
                                            </label>
                                            <div class="col-md-9">
                                                <input disabled="true" class="form-control" type="text" placeholder="Başvuran Ana Ajansın Adı" name="BasvuranAjansAdi" [(ngModel)]="PageData.BasvuranAjansAdi">
                                            </div>
                                        </div>
                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-3 label-control" style="color: white;" for="BasvuranAjansAdres">
                                                <span class="VerticalAlignSub">Adresi:</span>
                                                <span class="gerekli"> *</span>
                                            </label>
                                            <div class="col-md-9">
                                                <textarea disabled="true" class="form-control" placeholder="Başvuran Ana Ajansın Adres bilgileri" name="BasvuranAjansAdres" [(ngModel)]="PageData.BasvuranAjansAdres"></textarea>
                                            </div>
                                        </div>
                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-3 label-control" style="color: white;" for="BasvuranAjansBagliOlduguNetwork">
                                                <span class="VerticalAlignSub">Bağlı Olduğu Network:</span>
                                            </label>
                                            <div class="col-md-9">
                                                <input disabled="true" class="form-control" type="text" placeholder="Bağlı Olduğu Network" name="BasvuranAjansBagliOlduguNetwork" [(ngModel)]="PageData.BasvuranAjansBagliOlduguNetwork">
                                            </div>
                                        </div>
                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-3 label-control" style="color: white;" for="BasvuranAjansWebSitesi">
                                                <span class="VerticalAlignSub">Web Sitesi:</span>
                                            </label>
                                            <div class="col-md-9">
                                                <input disabled="true" class="form-control" type="text" placeholder="Web Sitesi" name="BasvuranAjansWebSitesi" [(ngModel)]="PageData.BasvuranAjansWebSitesi">
                                            </div>
                                        </div>
                                        <div class="form-group row NoMarginLeftRight" *ngFor="let item of PageData.BasvuranAjansYetkililer; let i = index;">
                                            <label class="col-md-3 label-control" style="color: white;" for="BasvuranAjansYetkililer{{i}}">
                                                <span class="VerticalAlignSub">Yetkili {{i + 1}}:</span>
                                                <span class="gerekli" *ngIf="i == 0"> *</span>
                                                <img src="../../assets/eksi.png" class="arti">
                                            </label>
                                            <div class="col-md-9">
                                                <input disabled="true" class="form-control" type="text" placeholder="Ahmet Çetin, Gen. Md. (Pazarlama Md., Reklam Md., Ürün Md., vb. )" name="BasvuranAjansYetkililer{{i}}Adi" [(ngModel)]="PageData.BasvuranAjansYetkililer[i]['AdSoyad']">
                                                <input disabled="true" class="form-control" type="text" placeholder="ornek@effie.org" name="BasvuranAjansYetkililer{{i}}Eposta" [(ngModel)]="PageData.BasvuranAjansYetkililer[i]['Eposta']">
                                            </div>
                                        </div>
                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-12 label-control" style="color: white; text-align: center;">
                                                <button class="EkleButton">Yetkili Ekle <img
                                                        src="../../assets/arti.png" class="arti"
                                                        style="right: initial;" /></button>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="form-body">
                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-3 label-control" style="color: white;" for="projectinput1">
                                                <span class="VerticalAlignSub">Katkıda Bulunan Ajanslar</span>
                                            </label>
                                            <div class="col-md-9" style="color: #fff;">Başvuran Ana Ajansın yanı sıra, kampanyanın çeşitli alan/disiplinlerde genişlemesini sağlayan/katkıda bulunan ajansları lütfen belirtiniz. (Maksimum 4 Adet)</div>
                                        </div>
                                        <div class="form-group row NoMarginLeftRight" *ngFor="let item of PageData.KatkidaBulunanAjanslar; let i = index;">
                                            <label class="col-md-3 label-control" style="color: white;" for="KatkidaBulunanAjanslar{{i}}">
                                                <span class="VerticalAlignSub">Ajans {{i + 1}}:</span>
                                                <img src="../../assets/eksi.png" class="arti">
                                            </label>
                                            <div class="col-md-9">
                                                <input disabled="true" class="form-control" type="text" placeholder="Adı, Bağlı Olduğu Network" name="KatkidaBulunanAjanslar{{i}}" [(ngModel)]="PageData.KatkidaBulunanAjanslar[i].Ajans">
                                            </div>
                                        </div>
                                        <div class="form-group row NoMarginLeftRight" *ngIf="PageData.KatkidaBulunanAjanslar.length < 4">
                                            <label class="col-md-12 label-control" style="color: white; text-align: center;">
                                                <button class="EkleButton">Ajans Ekle <img
                                                        src="../../assets/arti.png" class="arti"
                                                        style="right: initial;" /></button>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="form-body">
                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-3 label-control" style="color: white;" for="projectinput1">
                                                <span class="VerticalAlignSub">Formu Dolduran:</span>
                                            </label>
                                            <div class="col-md-9">&nbsp;</div>
                                        </div>
                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-3 label-control" style="color: white;" for="FormuDolduranAdi">
                                                <span class="VerticalAlignSub">Adı, Soyadı:</span>
                                                <span class="gerekli"> *</span>
                                            </label>
                                            <div class="col-md-9">
                                                <input disabled="true" class="form-control" type="text" placeholder="Formu Dolduran Kişinin Adı, Soyadı" name="FormuDolduranAdi" [(ngModel)]="PageData.FormuDolduranAdi">
                                            </div>
                                        </div>
                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-3 label-control" style="color: white;" for="FormuDolduranGorevi">
                                                <span class="VerticalAlignSub">Görevi:</span>
                                                <span class="gerekli"> *</span>
                                            </label>
                                            <div class="col-md-9">
                                                <input disabled="true" class="form-control" type="text" placeholder="Formu Dolduran Kişinin Görevi" name="FormuDolduranGorevi" [(ngModel)]="PageData.FormuDolduranGorevi">
                                            </div>
                                        </div>
                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-3 label-control" style="color: white;" for="FormuDolduranTelefon">
                                                <span class="VerticalAlignSub">Telefon: </span>
                                                <span class="gerekli"> *</span>
                                            </label>
                                            <div class="col-md-9">
                                                <input disabled="true" class="form-control" type="tel" id="FormuDolduranTelefon" placeholder="Formu Dolduran Kişinin Telefonu" name="FormuDolduranTelefon" maxlength="15" [ngModel]="PageData.FormuDolduranTelefon">
                                            </div>
                                        </div>
                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-3 label-control" style="color: white;" for="FormuDolduranEposta">
                                                <span class="VerticalAlignSub">E-Posta:</span>
                                                <span class="gerekli"> *</span>
                                            </label>
                                            <div class="col-md-9">
                                                <input disabled="true" class="form-control" type="email" placeholder="Formu Dolduran Kişinin E-Posta Adresi" name="FormuDolduranEposta" [(ngModel)]="PageData.FormuDolduranEposta">
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="actions clearfix">
                                <ul role="menu" class="Pagination">
                                    <li (click)="AdimDegistir(1)"><a role="menuitem">Bir Önceki Adım</a></li>
                                    <li (click)="AdimDegistir(3)"><a role="menuitem">Bir Sonraki Adım</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </article>
    </div>
</section>