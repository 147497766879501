<section id="IcerikContainer">
    <div class="dev-wrapper dev-entrant-home dev-wrapper-entry-home dev-flex-container IcerikSubContainer">
        <article id="content" class="dev-flex-child">
            <div class="wizard-v4-content">
                <div class="wizard-form">
                    <div class="wizard-header">
                        <h3 class="heading">Başvuru Güncelleme</h3>
                        <!-- <p>Formu doldurup bir sonraki adıma geçiniz, veya istediğiniz adıma geçebilirsiniz.</p> -->
                    </div>
                    <div class="form-register gonderilecek" action="#" method="post" *ngIf="Yuklendi === true && PageData.EBook_Onay === 2">
                        <div id="form-total" role="application" class="wizard clearfix">
                            <div class="steps clearfix">
                                <ul role="tablist">
                                    <li role="tab" class="done">
                                        <a (click)="AdimDegistir(1)">
                                            <div class="title">
                                                <span class="step-cizgi-left renkli"></span>
                                                <span class="step-cizgi-right renkli"></span>
                                                <span class="step-icon">1</span>
                                                <span class="step-text">Kategori Seçimi</span>
                                                <span class="badge" *ngIf="PageData.Adim1Durum === false">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="done">
                                        <a (click)="AdimDegistir(2)">
                                            <div class="title">
                                                <span class="step-cizgi-left renkli"></span>
                                                <span class="step-cizgi-right renkli"></span>
                                                <span class="step-icon">2</span>
                                                <span class="step-text">Künye</span>
                                                <span class="badge" *ngIf="PageData.Adim2Durum === false">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="done">
                                        <a (click)="AdimDegistir(3)">
                                            <div class="title">
                                                <span class="step-cizgi-left renkli"></span>
                                                <span class="step-cizgi-right renkli"></span>
                                                <span class="step-icon">3</span>
                                                <span class="step-text">Özet</span>
                                                <span class="badge" *ngIf="PageData.Adim3Durum === false">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="done">
                                        <a (click)="AdimDegistir(4)">
                                            <div class="title">
                                                <span class="step-cizgi-left renkli"></span>
                                                <span class="step-cizgi-right renkli"></span>
                                                <span class="step-icon">4</span>
                                                <span class="step-text">Sorular A</span>
                                                <span class="badge" *ngIf="PageData.Adim4Durum === false">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="done">
                                        <a (click)="AdimDegistir(5)">
                                            <div class="title">
                                                <span class="step-cizgi-left renkli"></span>
                                                <span class="step-cizgi-right renkli"></span>
                                                <span class="step-icon">5</span>
                                                <span class="step-text">Sorular B</span>
                                                <span class="badge" *ngIf="PageData.Adim5Durum === false">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="done">
                                        <a (click)="AdimDegistir(6)">
                                            <div class="title">
                                                <span class="step-cizgi-left renkli"></span>
                                                <span class="step-cizgi-right renkli"></span>
                                                <span class="step-icon">6</span>
                                                <span class="step-text">Sorular C</span>
                                                <span class="badge" *ngIf="PageData.Adim6Durum === false">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="done">
                                        <a (click)="AdimDegistir(7)">
                                            <div class="title">
                                                <span class="step-cizgi-left renkli"></span>
                                                <span class="step-cizgi-right renkli"></span>
                                                <span class="step-icon">7</span>
                                                <span class="step-text">Sorular D</span>
                                                <span class="badge" *ngIf="PageData.Adim8DurumEbook === false">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="done">
                                        <a>
                                            <div class="title">
                                                <span class="step-cizgi-left renkli"></span>
                                                <span class="step-cizgi-right"></span>
                                                <span class="step-icon">8</span>
                                                <span class="step-text">Dosya Yükleme</span>
                                                <span class="badge" *ngIf="PageData.Adim8DurumEbook === false">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <!-- <li role="tab" class="">
                                        <a (click)="AdimDegistir(8)">
                                            <div class="title">
                                                <span class="step-cizgi-left"></span>
                                                <span class="step-cizgi-right"></span>
                                                <span class="step-icon">8</span>
                                                <span class="step-text">Sayfa Sayısı Doğrulama</span>
                                                <span class="badge" *ngIf="PageData.Adim8DurumEbook === false">!</span>
                                            </div>
                                        </a>
                                    </li> -->
                                    <!-- <li role="tab" class="">
                                        <a (click)="AdimDegistir(9)">
                                            <div class="title">
                                                <span class="step-cizgi-left"></span>
                                                <span class="step-cizgi-right"></span>
                                                <span class="step-icon">9</span>
                                                <span class="step-text">İzinler Belgesi</span>
                                                <span class="badge" *ngIf="PageData.Adim9Durum === false">!</span>
                                            </div>
                                        </a>
                                    </li> -->
                                    <li role="tab" class="">
                                        <a (click)="AdimDegistir(9)">
                                            <div class="title">
                                                <span class="step-cizgi-left"></span>
                                                <span class="step-cizgi-right"></span>
                                                <span class="step-icon">9</span>
                                                <span class="step-text">Ajansa Gönder</span>

                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div class="content clearfix">

                                <form class="form form-horizontal AdimliForm" novalidate="">
                                    <p *ngIf="GlobalYonetim.Adim8.Header.length > 0" [innerHtml]="GlobalYonetim.Adim8.Header" style="max-width: none; width: 100%; margin-bottom: 10px"></p>

                                    <table class="Table">
                                        <thead>
                                            <tr>
                                                <th>Adı</th>
                                                <th>Türü</th>
                                                <th>Boyutu</th>
                                                <th>Tarihi</th>
                                                <th style="text-align:center;">Ön İzleme</th>
                                                <th style="text-align:center;">Sil</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngIf="Dosyalar.length == 0">
                                                <td colspan="6" align="center">Yüklenmiş dosyanız bulunmamaktadır.</td>
                                            </tr>
                                            <tr *ngFor="let item of Dosyalar; let i = index;">
                                                <td *ngIf="item.Type !== 'Link'">{{item.DosyaAdi}}</td>
                                                <td *ngIf="item.Type === 'Link'"><a href="{{item.DosyaAdi}}" target="_blank">{{item.DosyaAdi}}</a></td>
                                                <td>{{DosyaType(item.Type)}}</td>
                                                <td>{{formatBytes(item.Boyut)}}</td>
                                                <td>{{item.Tarih | date:'dd.MM.yyyy HH:mm'}}</td>
                                                <td align="center">
                                                    <a *ngIf="item.Type !== 'Link'" target="_blank" href="https://api.effieturkiye.org/storage/dosyalar/{{EntryId}}/{{item.DosyaAdi}}" target="_blank" style="color: rgb(177, 152, 92);"><i
                                                            class="fas fa-eye"></i></a>
                                                    <a *ngIf="item.Type === 'Link'" target="_blank" href="{{item.DosyaAdi}}" target="_blank" style="color: rgb(177, 152, 92);"><i
                                                                class="fas fa-eye"></i></a>
                                                </td>
                                                <td align="center"><i class="fas fa-trash-alt" [swal]="DosyaSilSwal" (confirm)="DosyaSil(item.Id)" style="color: rgb(177, 152, 92); cursor: pointer;"></i></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table class="Table" *ngIf="Dosyalar.length < 6">
                                        <tbody>
                                            <tr>
                                                <td style="width: 50%;">
                                                    <button class="EkleButton" (click)="ModalOpen(UploadModal)">Dosya
                                                        Ekle <img src="../../assets/arti.png" class="arti"
                                                            style="right: initial; margin-top: 5px; top: inherit;" /></button>
                                                </td>
                                                <td style="width: 50%;">
                                                    <button class="EkleButton" (click)="ModalOpen(LinkModal)">Link
                                                        Ekle <img src="../../assets/arti.png" class="arti"
                                                            style="right: initial; margin-top: 5px; top: inherit;" /></button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </form>

                            </div>
                            <div class="actions clearfix">
                                <ul role="menu" class="Pagination">
                                    <li (click)="AdimDegistir(7)"><a role="menuitem">Bir Önceki Adım</a></li>
                                    <li (click)="AdimDegistir(9)"><a role="menuitem">Bir Sonraki Adım</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </article>
    </div>
    <ng-template #UploadModal let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h4 class="modal-title">Dosya Ekle</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="form-group row NoMarginLeftRight">
                <label class="col-md-3 label-control" for="KatkidaBulunanYetkililer">
                    <span class="VerticalAlignSub">Dosya:</span>
                    <span class="gerekli"> *</span>
                </label>
                <div class="col-md-9">
                    <input type="file" name="file[]" ng2FileSelect (change)="FileChange($event)" [uploader]="uploader" accept=".doc,.docx,.jpg,.jpeg,.gif,.png,.zip,.rar,.mp4,.pdf" />
                </div>
            </div>

            <div class="progress">
                <div class="progress-bar progress-bar-striped bg-success" role="progressbar" [style.width]="(uploadProgress) + '%'" [attr.aria-valuenow]="(uploadProgress)" aria-valuemin="0" aria-valuemax="100"></div>
            </div>

        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-block btn-raised white float-left ModalKapat" (click)="c('')">Kapat</button>
            <button type="button" [ladda]="isLoading" class="btn btn-block btn-raised white float-right ModalEkle" (click)="DosyaModalSave()">Ekle</button>
        </div>
    </ng-template>

    <ng-template #LinkModal let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h4 class="modal-title">Link Ekle</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="form-group row NoMarginLeftRight">
                <label class="col-md-2 label-control" for="KatkidaBulunanYetkililer">
                    <span class="VerticalAlignSub">URL:</span>
                    <span class="gerekli"> *</span>
                </label>
                <div class="col-md-10">
                    <input type="text" class="form-control" name="link" [(ngModel)]="link" />
                </div>
            </div>

        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-block btn-raised white float-left ModalKapat" (click)="c('')">Kapat</button>
            <button type="button" [ladda]="isLoading" class="btn btn-block btn-raised white float-right ModalEkle" (click)="LinkModalSave()">Ekle</button>
        </div>
    </ng-template>
</section>