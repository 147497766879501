import { Component, OnInit, NgZone } from '@angular/core';
import { Globals } from '../providers/globals/globals';
import { HttpClient, HttpHandler, HttpRequest, HttpParams, HttpHeaders } from '@angular/common/http';
import { MessageServiceService } from '../services/message-service.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-solmenu',
	templateUrl: './solmenu.component.html',
	styleUrls: ['./solmenu.component.css']
})
export class SolmenuComponent implements OnInit {
	PageData: any;
	Yuklendi = false;
	gelen = 0;
	giden = 0;
	constructor(
		public globals: Globals,
		private http: HttpClient,
		private messageService: MessageServiceService,
		private zone: NgZone,
		private router: Router,
	) { }

	ngOnInit() {
		this.GetData();

		this.messageService.getMessage().subscribe(message => {
			if (message.text === 'ProjeGuncellendi') {
				this.ProjeSay();
			}
		});
	}

	GetData() {
		const params = new HttpParams();
		let headers = new HttpHeaders();
		headers = headers.append('Accept', 'application/json, text/javascript, */*; q=0.01');
		headers = headers.append('Content-Type', 'application/json');
		headers = headers.append('Authorization', 'Bearer ' + this.globals.User.Token);

		this.http.get('https://api.effieturkiye.org/EditorEbookProjeler', { params, headers }).subscribe(
			(response) => {
				this.globals.projeler = response['Projeler'];

				this.globals.Kategoriler = response['Kategoriler'];
				this.globals.Kategoriler2025 = response['Kategoriler2025'];
				this.globals.Kategoriler2024 = response['Kategoriler2024'];
				this.globals.Kategoriler2023 = response['Kategoriler2023'];
				this.globals.Kategoriler2022 = response['Kategoriler2022'];
				// this.globals.projeler.Adim2 = JSON.parse(this.globals.projeler.Adim2);
				this.globals.Sorular = response['Sorular'];
				this.globals.SorularSurdurulebilir = response['SorularSurdurulebilir'];
				
				// this.globals.projeler.Adim2 = JSON.parse(this.globals.projeler.Adim2);
				this.globals.Yonetim = response['Yonetim'];
				this.globals.Yonetim.Yuklendi = true;

				this.globals.Yonetim2025 = response['Yonetim2025'];
				this.globals.Yonetim2025.Yuklendi = true;
				this.globals.Yonetim2024 = response['Yonetim2024'];
				this.globals.Yonetim2024.Yuklendi = true;
				this.globals.Yonetim2023 = response['Yonetim2023'];
				this.globals.Yonetim2023.Yuklendi = true;
				this.globals.Yonetim2022 = response['Yonetim2022'];
				this.globals.Yonetim2022.Yuklendi = true;
				// console.log(this.globals.projeler);
				// console.log(this.globals.Yonetim);
				this.messageService.sendMessage('ProjeGuncellendi');
				this.messageService.sendMessage('Yonetim');
				this.messageService.sendMessage('Kullanici');
			},
			error => {
				console.log('Error', error);
				if (error.status === 401) {
					// localStorage.clear();
					// this.globals.Sifirla();
					// this.router.navigateByUrl('/home');
					this.messageService.sendMessage('logout');
				}
				// this.showError('Bir hata oluştu, Lütfen tekrar deneyiniz');
			}
		);
	}

	ProjeSay() {
		this.zone.run(() => {
			this.gelen = 0;
			this.giden = 0;
			this.globals.projeler.forEach(element => {
				if (element.EBook_Onay === 2) {
					this.gelen++;
				}
				else if (element.EBook_Onay === 3) {
					this.giden++;
				}
			});
		});
	}

	Logout() {
		this.messageService.sendMessage('logout');
	}
}
