import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Globals } from './providers/globals/globals';
import { MessageServiceService } from './services/message-service.service';
import { ToastrModule } from 'ngx-toastr';
import { LaddaModule } from 'angular2-ladda';
import { HttpClientModule } from '@angular/common/http';
import { QuillModule } from 'ngx-quill'
import { FileUploadModule } from 'ng2-file-upload';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NumberOnlyDirective } from './services/number.directive';
import { environment } from '../environments/environment';
import { HtmlPipe } from './html-pipe.pipe';


import { NavbarComponent } from './navbar/navbar.component';
import { SolmenuComponent } from './solmenu/solmenu.component';
import { GelenComponent } from './gelen/gelen.component';
import { GidenComponent	 } from './giden/giden.component';
import { HomepageComponent } from './homepage/homepage.component';

import { EditentryAdim1Component } from './editentryadim1/editentryadim1.component';
import { EditentryAdim2Component } from './editentryadim2/editentryadim2.component';
import { EditentryAdim3Component } from './editentryadim3/editentryadim3.component';
import { EditentryAdim4Component } from './editentryadim4/editentryadim4.component';
import { EditentryAdim8Component } from './editentryadim8/editentryadim8.component';
import { EditentryAdim9Component } from './editentryadim9/editentryadim9.component';

import { ViewentryAdim1Component } from './viewentryadim1/viewentryadim1.component';
import { ViewentryAdim2Component } from './viewentryadim2/viewentryadim2.component';
import { ViewentryAdim3Component } from './viewentryadim3/viewentryadim3.component';
import { ViewentryAdim4Component } from './viewentryadim4/viewentryadim4.component';
import { ViewentryAdim8Component } from './viewentryadim8/viewentryadim8.component';

import { DosyaOlusturComponent } from './dosyaolustur/dosyaolustur.component';

import { FooterComponent } from './footer/footer.component';

@NgModule({
	declarations: [
		NumberOnlyDirective,
		AppComponent,
		GelenComponent,
		GidenComponent,
		NavbarComponent,
		HomepageComponent,
		SolmenuComponent,
		EditentryAdim1Component,
		EditentryAdim2Component,
		EditentryAdim3Component,
		EditentryAdim4Component,
		EditentryAdim8Component,
		EditentryAdim9Component,
		ViewentryAdim1Component,
		ViewentryAdim2Component,
		ViewentryAdim3Component,
		ViewentryAdim4Component,
		ViewentryAdim8Component,
		DosyaOlusturComponent,
		FooterComponent,
		HtmlPipe,
	],
	imports: [
		CommonModule,
		BrowserAnimationsModule,
		ToastrModule.forRoot(),
		LaddaModule,
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		QuillModule.forRoot(),
		FileUploadModule,
		SweetAlert2Module.forRoot(),
		NgbModule,
		AppRoutingModule
	],
	providers: [
		Globals,
		MessageServiceService,
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
