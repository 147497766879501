<section id="SolMenu">
    <header>
        <h1>Sn. {{globals.User.Decoded.AdSoyad}}</h1>
        <!-- <p>Hesabım</p> -->
    </header>
    <nav id="user-entry-nav">
        <h6>Genel Bakış</h6>
        <ul class="mg-app-nav-menu dev-nav-progress">
            <li>
                <a [routerLink]="['/gelen']">
                    <span>Gelen</span>
                    <span class="dev-count">{{gelen}}</span>
                </a>
            </li>
        </ul>
        <ul class="mg-app-nav-menu dev-nav-progress">
            <li class="dev-active">
                <a [routerLink]="['/giden']">
                    <span>Giden</span>
                    <span class="dev-count">{{giden}}</span>
                </a>
            </li>
        </ul>

        <h6>Hesabım</h6>
        <ul class="mg-app-nav-menu dev-nav-progress">
            <!-- <li>
                <a [routerLink]="['/hesapbilgileri']">
                    <span>Hesap bilgileri</span>
                </a>
            </li> -->
            <li>
                <a (click)="Logout()">
                    <span>Çıkış</span>
                    <!-- <span class="dev-count">1</span> -->
                </a>
            </li>
        </ul>
    </nav>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
</section>