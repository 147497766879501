import { Component, OnInit, NgZone, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, ModalDismissReasons, NgbDateAdapter, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { Globals } from '../providers/globals/globals';
import { HttpClient, HttpHandler, HttpRequest, HttpParams, HttpHeaders } from '@angular/common/http';

import { Subscription } from 'rxjs/Subscription';
import { MessageServiceService } from '../services/message-service.service';

@Component({
	selector: 'app-editentryadim3',
	templateUrl: './editentryadim3.component.html',
	styleUrls: ['./editentryadim3.component.css'],
	encapsulation: ViewEncapsulation.None,
})
export class EditentryAdim3Component implements OnInit {
	GlobalYonetim: any;
	subscription: Subscription;
	EntryId;
	PageData: any;
	OrgData: any;
	IlkSurdurulebilirlik = 0;
	SonSurdurulebilirlik = 0;
	Yuklendi = false;
	Yil = '';

	ModalTitle: string = '';
	ModalData: Object = [];
	constructor(
		private http: HttpClient,
		private route: ActivatedRoute,
		private router: Router,
		private toastr: ToastrService,
		private modalService: NgbModal,
		private zone: NgZone,
		private globals: Globals,
		private messageService: MessageServiceService,
	) {

	}


	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	ngOnInit() {
		this.route.paramMap.subscribe(params => {
			this.EntryId = params.get('EntryId');
		});

		if (this.globals.Yonetim.Yuklendi === true) {
			this.GlobalYonetim = this.globals.YilaGoreGlobalYonetimAl(this.Yil);
			window.scrollTo(0, 0);
			this.DataYukle();
		}

		this.subscription = this.messageService.getMessage().subscribe(message => {
			if (message.text === 'Yonetim') {
				this.GlobalYonetim = this.globals.YilaGoreGlobalYonetimAl(this.Yil);
				window.scrollTo(0, 0);
				this.DataYukle();
			}
		});
	}

	DataYukle() {
		var result = this.globals.projeler.filter(function (elem, i, rep) {
			return elem.Id === this;
		}, parseInt(this.EntryId));
		if (result.length) {
			this.PageData = JSON.parse(JSON.stringify(result[0]));
			// if (this.PageData.Adim11Durum === 1) {
			// 	this.zone.run(() => {
			// 		this.router.navigateByUrl('/dashboard');
			// 	});
			// }
			this.Yil = this.PageData['Yil'];
			this.GlobalYonetim = this.globals.YilaGoreGlobalYonetimAl(this.Yil);
			
			this.OrgData = JSON.parse(JSON.stringify(result[0]));
			let that = this;
			this.IlkSurdurulebilirlik = this.PageData['Surdurulebilir'];
			this.SonSurdurulebilirlik = this.PageData['Surdurulebilir'];
			let wordCount = this.PageData.Adim3.Satir2 ? this.PageData.Adim3.Satir2.split(/\s+/) : 0;
			this.PageData.Adim3.Satir2WordCount = wordCount ? wordCount.length : 0;
			wordCount = this.PageData.Adim3.Satir3 ? this.PageData.Adim3.Satir3.split(/\s+/) : 0;
			this.PageData.Adim3.Satir3WordCount = wordCount ? wordCount.length : 0;
			wordCount = this.PageData.Adim3.Satir4 ? this.PageData.Adim3.Satir4.split(/\s+/) : 0;
			this.PageData.Adim3.Satir4WordCount = wordCount ? wordCount.length : 0;
			wordCount = this.PageData.Adim3.Satir5 ? this.PageData.Adim3.Satir5.split(/\s+/) : 0;
			this.PageData.Adim3.Satir5WordCount = wordCount ? wordCount.length : 0;
			wordCount = this.PageData.Adim3.Satir6 ? this.PageData.Adim3.Satir6.split(/\s+/) : 0;
			this.PageData.Adim3.Satir6WordCount = wordCount ? wordCount.length : 0;
			wordCount = this.PageData.Adim3.Satir8 ? this.PageData.Adim3.Satir8.split(/\s+/) : 0;
			this.PageData.Adim3.Satir8WordCount = wordCount ? wordCount.length : 0;

			this.Yuklendi = true;
			// console.log(this.PageData)
		}
	}



	showSuccess(mesaj: string) {
		this.toastr.success(mesaj, 'Tebrikler!');
	}

	showError(mesaj: string) {
		this.toastr.error(mesaj, 'Hata!');
	}

	showWarning(mesaj: string) {
		this.toastr.warning(mesaj, 'Dikkat!');
	}

	showInfo(mesaj: string) {
		this.toastr.info(mesaj);
	}

	AdimDegistir(sayfa: number) {

		// return;
		if (typeof sayfa !== 'undefined') {

			console.log(this.PageData.Adim3)
			let hata = false;
			if (this.PageData.Adim3.BasGun === 0) {
				// this.showWarning('Kampanya Başlangıç Tarihi Seçiniz.');
				hata = true;
				// return;
			}

			if (this.PageData.Adim3.BasAy === 0) {
				// this.showWarning('Kampanya Başlangıç Tarihi Seçiniz.');
				hata = true;
				// return;
			}

			if (this.PageData.Adim3.BasYil === 0) {
				// this.showWarning('Kampanya Başlangıç Tarihi Seçiniz.');
				hata = true;
				// return;
			}

			if (this.PageData.Adim3.BitGun === 0 && this.PageData.Adim3.DevamEdiyor === 0) {
				// this.showWarning('Kampanya Bitiş Tarihi Seçiniz.');
				hata = true;
				// return;
			}

			if (this.PageData.Adim3.BitAy === 0 && this.PageData.Adim3.DevamEdiyor === 0) {
				// this.showWarning('Kampanya Bitiş Tarihi Seçiniz.');
				hata = true;
				// return;
			}

			if (this.PageData.Adim3.BitYil === 0 && this.PageData.Adim3.DevamEdiyor === 0) {
				// this.showWarning('Kampanya Bitiş Tarihi Seçiniz.');
				hata = true;
				// return;
			}

			if (this.PageData.Adim3.Satir2WordCount === 0) {
				// this.showWarning('The Challange alanını boş bırakamazsınız.');
				hata = true;
				// return;
			}
			if (this.PageData.Adim3.Satir2WordCount > 30) {
				// this.showWarning('The Challange en fazla 30 kelimeden oluşabilir.');
				hata = true;
				// return;
			}
			if (this.PageData.Adim3.Satir3WordCount === 0) {
				// this.showWarning('The Insight alanını boş bırakamazsınız.');
				hata = true;
				// return;
			}
			if (this.PageData.Adim3.Satir3WordCount > 30) {
				// this.showWarning('The Insight en fazla 30 kelimeden oluşabilir.');
				hata = true;
				// return;
			}
			if (this.PageData.Adim3.Satir4WordCount === 0) {
				// this.showWarning('The Strategic Idea/Build alanını boş bırakamazsınız.');
				hata = true;
				// return;
			}
			if (this.PageData.Adim3.Satir4WordCount > 30) {
				// this.showWarning('The Strategic Idea/Build en fazla 30 kelimeden oluşabilir.');
				hata = true;
				// return;
			}
			if (this.PageData.Adim3.Satir5WordCount === 0) {
				// this.showWarning('Bringing the Strategy & Idea to Life alanını boş bırakamazsınız.');
				hata = true;
				// return;
			}
			if (this.PageData.Adim3.Satir5WordCount > 30) {
				// this.showWarning('Bringing the Strategy & Idea to Life en fazla 30 kelimeden oluşabilir.');
				hata = true;
				// return;
			}
			if (this.PageData.Adim3.Satir6WordCount === 0) {
				// this.showWarning('The Results alanını boş bırakamazsınız.');
				hata = true;
				// return;
			}
			if (this.PageData.Adim3.Satir6WordCount > 30) {
				// this.showWarning('The Results en fazla 30 kelimeden oluşabilir.');
				hata = true;
				// return;
			}

			if (this.PageData.Adim3.Satir8WordCount === 0) {
				// this.showWarning('Son Soru alanını boş bırakamazsınız.');
				hata = true;
				// return;
			}
			if (this.PageData.Adim3.Satir8WordCount > 100) {
				// this.showWarning('Son Soru en fazla 100 kelimeden oluşabilir.');
				hata = true;
				// return;
			}

			const tempcontrol = JSON.parse(JSON.stringify(this.PageData));
			delete tempcontrol.Adim3.Satir2WordCount;
			delete tempcontrol.Adim3.Satir3WordCount;
			delete tempcontrol.Adim3.Satir4WordCount;
			delete tempcontrol.Adim3.Satir5WordCount;
			delete tempcontrol.Adim3.Satir6WordCount;
			delete tempcontrol.Adim3.Satir8WordCount;

			// console.log(this.PageData.Adim3)
			// console.log(tempcontrol.Adim3)
			// console.log(this.OrgData.Adim3)

			if (JSON.stringify(tempcontrol.Adim3) !== JSON.stringify(this.OrgData.Adim3)) {
				let UpdateData;

				UpdateData = {
					'BasGun': this.PageData.Adim3.BasGun,
					'BasAy': this.PageData.Adim3.BasAy,
					'BasYil': this.PageData.Adim3.BasYil,
					'BitGun': this.PageData.Adim3.BitGun,
					'BitAy': this.PageData.Adim3.BitAy,
					'BitYil': this.PageData.Adim3.BitYil,
					'DevamEdiyor': this.PageData.Adim3.DevamEdiyor,
					'Satir2': this.PageData.Adim3.Satir2.trim(),
					'Satir3': this.PageData.Adim3.Satir3.trim(),
					'Satir4': this.PageData.Adim3.Satir4.trim(),
					'Satir5': this.PageData.Adim3.Satir5.trim(),
					'Satir6': this.PageData.Adim3.Satir6.trim(),
					'Satir8': this.PageData.Adim3.Satir8.trim(),
					'Id': this.EntryId,
					'hata': hata
				}


				// console.log(JSON.stringify(UpdateData));
				// console.log(UpdateData);

				const params = new HttpParams();
				let headers = new HttpHeaders();
				headers = headers.append('Accept', 'application/json, text/javascript, */*; q=0.01');
				headers = headers.append('Content-Type', 'application/json');
				headers = headers.append('Authorization', 'Bearer ' + this.globals.User.Token);

				this.http.post('https://api.effieturkiye.org/ProjeOzet', UpdateData, { params, headers }).subscribe(
					(response) => {
						const index = this.globals.projeler.findIndex(elem => elem.Id === Number(this.EntryId));
						this.globals.projeler[index] = response;
						this.showSuccess('Üçüncü Adım Kaydedildi, yönlendiriliyorsunuz.');
						this.messageService.sendMessage('ProjeGuncellendi');
						this.router.navigateByUrl('/editentry/' + this.EntryId + '/' + sayfa);
					},
					error => {
						//  console.log('Error', error)
						this.showError('Bir hata oluştu, Lütfen tekrar deneyiniz');
					}
				);
			}
			else {
				this.router.navigateByUrl('/editentry/' + this.EntryId + '/' + sayfa);
			}


		}
		else {
			this.showError('Bir hata oluştu.');
		}
	}

	KarakterSayici($event, Satir: string) {
		// console.log($event);
		// if (Satir === 'Satir3') {
		// 	let wordCount = this.PageData.Adim3.Satir3.trim() ? this.PageData.Adim3.Satir3.trim().split(/\s+/) : 0;
		// 	this.PageData.Adim3.Satir3WordCount = wordCount ? wordCount.length : 0;
		// }
		if (Satir === 'Satir2') {
			let wordCount = this.PageData.Adim3.Satir2.trim() ? this.PageData.Adim3.Satir2.trim().split(/\s+/) : 0;
			if (wordCount.length > 30) {
				let temp = this.PageData.Adim3.Satir2.trim().split(/\s+/);
				wordCount.length = temp.length = 30;
				this.PageData.Adim3.Satir2 = temp.join(" ");
			}
			this.PageData.Adim3.Satir2WordCount = wordCount ? wordCount.length : 0;
		}
		if (Satir === 'Satir3') {
			let wordCount = this.PageData.Adim3.Satir3.trim() ? this.PageData.Adim3.Satir3.trim().split(/\s+/) : 0;
			if (wordCount.length > 30) {
				let temp = this.PageData.Adim3.Satir3.trim().split(/\s+/);
				wordCount.length = temp.length = 30;
				this.PageData.Adim3.Satir3 = temp.join(" ");
			}
			this.PageData.Adim3.Satir3WordCount = wordCount ? wordCount.length : 0;
		}
		if (Satir === 'Satir4') {
			let wordCount = this.PageData.Adim3.Satir4.trim() ? this.PageData.Adim3.Satir4.trim().split(/\s+/) : 0;
			if (wordCount.length > 30) {
				let temp = this.PageData.Adim3.Satir4.trim().split(/\s+/);
				wordCount.length = temp.length = 30;
				this.PageData.Adim3.Satir4 = temp.join(" ");
			}
			this.PageData.Adim3.Satir4WordCount = wordCount ? wordCount.length : 0;
		}
		if (Satir === 'Satir5') {
			let wordCount = this.PageData.Adim3.Satir5.trim() ? this.PageData.Adim3.Satir5.trim().split(/\s+/) : 0;
			if (wordCount.length > 30) {
				let temp = this.PageData.Adim3.Satir5.trim().split(/\s+/);
				wordCount.length = temp.length = 30;
				this.PageData.Adim3.Satir5 = temp.join(" ");
			}
			this.PageData.Adim3.Satir5WordCount = wordCount ? wordCount.length : 0;
		}
		if (Satir === 'Satir6') {
			let wordCount = this.PageData.Adim3.Satir6.trim() ? this.PageData.Adim3.Satir6.trim().split(/\s+/) : 0;
			if (wordCount.length > 30) {
				let temp = this.PageData.Adim3.Satir6.trim().split(/\s+/);
				wordCount.length = temp.length = 30;
				this.PageData.Adim3.Satir6 = temp.join(" ");
			}
			this.PageData.Adim3.Satir6WordCount = wordCount ? wordCount.length : 0;
		}
		if (Satir === 'Satir8') {
			let wordCount = this.PageData.Adim3.Satir8.trim() ? this.PageData.Adim3.Satir8.trim().split(/\s+/) : 0;
			if (wordCount.length > 100) {
				let temp = this.PageData.Adim3.Satir8.trim().split(/\s+/);
				wordCount.length = temp.length = 100;
				this.PageData.Adim3.Satir8 = temp.join(" ");
			}
			this.PageData.Adim3.Satir8WordCount = wordCount ? wordCount.length : 0;
		}
	}

	DevamEdiyorChange($event) {
		// console.log($event);
		if ($event === true) {
			this.PageData.Adim3.BitAy = 0;
			this.PageData.Adim3.BitGun = 0;
			this.PageData.Adim3.BitYil = 0;
			return;
		}
	}

}
