<section class="mg-app-io mg-app-login">
	<header>
		<div class="dev-program-banner"><img src="assets/1.jpg"></div>
		<h3 class="renkli">Hoş geldiniz</h3>
		<!-- <div>
			<p>Buraya kaydolun veya hesap bilgilerinizle giriş yapın.</p>
		</div> -->
	</header>
	<article>
		<div class="dev-wrapper dev-flex dev-login">
			<div class="dev-flex-child">
				<div>
					<h3>Bilgileriniz kontrol ediliyor</h3>
				</div>
				
			</div>
			
		</div>
	</article>
</section>