import { Component, OnInit } from '@angular/core';
import { Globals } from '../providers/globals/globals';

@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
	
	constructor(public globals: Globals) { }

	ngOnInit() {
		
	}

}
