import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { MessageServiceService } from '../services/message-service.service';
import { Globals } from '../providers/globals/globals';
import { ToastrService } from 'ngx-toastr';
import { LaddaModule } from 'angular2-ladda';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

@Component({
	selector: 'app-homepage',
	templateUrl: './homepage.component.html',
	styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit {
	isLoading: boolean = false;
	toggleLoading() {
		this.isLoading = !this.isLoading;
	}

	constructor(
		private router: Router,
		private toastr: ToastrService,
		private messageService: MessageServiceService,
		private globals: Globals,
		private http: HttpClient,
		private zone: NgZone,
	) {

	}

	ngOnInit() {
	}

}
