import { Component, OnInit, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, ModalDismissReasons, NgbDateAdapter, NgbDateNativeAdapter, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { FileUploader, FileItem, ParsedResponseHeaders } from "ng2-file-upload";
import { Globals } from '../providers/globals/globals';
import { HttpClient, HttpHandler, HttpRequest, HttpParams, HttpHeaders } from '@angular/common/http';

import { Subscription } from 'rxjs/Subscription';
import { MessageServiceService } from '../services/message-service.service';

@Component({
	selector: 'app-editentryadim2',
	templateUrl: './editentryadim2.component.html',
	styleUrls: ['./editentryadim2.component.css']
})
export class EditentryAdim2Component implements OnInit {
	Yil = '';
	GlobalYonetim: any;
	subscription: Subscription;
	EntryId;
	PageData: any;
	OrgData: any;
	Kategoriler: any;
	IlkSurdurulebilirlik = false;
	SonSurdurulebilirlik = false;
	Yuklendi = false;
	KatkidaBulunanAjanslarYeni = '';
	ReklamverenYetkiliSilSwal = {
		title: 'Emin misiniz?',
		text: 'Reklamveren Yetkilisi Silmek Üzeresiniz',
		type: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#0CC27E',
		cancelButtonColor: '#FF586B',
		confirmButtonText: 'Evet, silinsin!',
		cancelButtonText: 'İptal'
	};
	TempSubKategoriler: any;

	BasvuranAjansYetkiliSilSwal = {
		title: 'Emin misiniz?',
		text: 'Basvuran Ana Ajans Yetkilisi Silmek Üzeresiniz',
		type: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#0CC27E',
		cancelButtonColor: '#FF586B',
		confirmButtonText: 'Evet, silinsin!',
		cancelButtonText: 'İptal'
	};

	KatkidaBulunanAjansSilSwal = {
		title: 'Emin misiniz?',
		text: 'Katkıda Bulunan Ajans Silmek Üzeresiniz',
		type: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#0CC27E',
		cancelButtonColor: '#FF586B',
		confirmButtonText: 'Evet, silinsin!',
		cancelButtonText: 'İptal'
	};

	YeniReklamverenYetkiliAdi = '';
	YeniReklamverenYetkiliEposta = '';

	YeniBasvuranAjansYetkiliAdi = '';
	YeniBasvuranAjansYetkiliEposta = '';
	YeniKatkidaBulunanAjans = '';

	selectedFile: any;
	_returnedURL = '';
	// uploadProgress: Observable<number>;
	uploadProgress: 0;
	isLoading = false;
	uploader: FileUploader = new FileUploader({
		url: 'https://api.effieturkiye.org/LogoEbook',
		isHTML5: true,
		removeAfterUpload: true,
		autoUpload: false,
		// maxFileSize: 10 * 1024
		// * 1024
		allowedMimeType: ['image/jpeg', 'image/png']
	});

	response: string;

	ModalTitle: string = '';
	ModalData: Object = [];
	constructor(
		private http: HttpClient,
		private route: ActivatedRoute,
		private router: Router,
		private toastr: ToastrService,
		private modalService: NgbModal,
		private zone: NgZone,
		private globals: Globals,
		private messageService: MessageServiceService,
	) {

	}

	ModalOpen(content) {
		this.modalService.open(content, { size: 'lg' }).result.then((result) => {
			if (result === 'SaveAndClose') {
				this.YeniReklamverenYetkiliAdi = '';
				this.YeniReklamverenYetkiliEposta = '';
				this.YeniBasvuranAjansYetkiliAdi = '';
				this.YeniBasvuranAjansYetkiliEposta = '';
				this.YeniKatkidaBulunanAjans = '';
			}
			else {
				this.YeniReklamverenYetkiliAdi = '';
				this.YeniReklamverenYetkiliEposta = '';
				this.YeniBasvuranAjansYetkiliAdi = '';
				this.YeniBasvuranAjansYetkiliEposta = '';
				this.YeniKatkidaBulunanAjans = '';
			}
		}, (reason) => {
			this.YeniReklamverenYetkiliAdi = '';
			this.YeniReklamverenYetkiliEposta = '';
			this.YeniBasvuranAjansYetkiliAdi = '';
			this.YeniBasvuranAjansYetkiliEposta = '';
			this.YeniKatkidaBulunanAjans = '';
		});
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	ngOnInit() {
		this.route.paramMap.subscribe(params => {
			this.EntryId = params.get('EntryId');
		});

		if (this.globals.Yonetim.Yuklendi === true) {
			this.GlobalYonetim = this.globals.YilaGoreGlobalYonetimAl(this.Yil);
			this.Kategoriler = this.globals.YilaGoreKategoriAl(this.Yil);
			window.scrollTo(0, 0);
			this.DataYukle();
		}

		this.subscription = this.messageService.getMessage().subscribe(message => {
			if (message.text === 'Yonetim') {
				this.GlobalYonetim = this.globals.YilaGoreGlobalYonetimAl(this.Yil);
				this.Kategoriler = this.globals.YilaGoreKategoriAl(this.Yil);
				window.scrollTo(0, 0);
				this.DataYukle();
			}
		});

		this.uploader.response.subscribe(res => this.response = res);
		this.uploader.authToken = 'Bearer ' + this.globals.User.Token;
		this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
			form.append('ProjeId', this.EntryId);
			form.append('Authorization', 'Bearer ' + this.globals.User.Token);
		};

		this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; console.log("dosya eklendi"); };
		this.uploader.onErrorItem = (item, response, status, headers) => this.onErrorItem(item, response, status, headers);
		this.uploader.onSuccessItem = (item, response, status, headers) => this.onSuccessItem(item, response, status, headers);
		this.uploader.onProgressItem = (progress: any) => {
			this.uploadProgress = progress['progress'];
		};
	}

	onSuccessItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
		let data = JSON.parse(response); //success server response
		// console.log(data);
		const index = this.globals.projeler.findIndex(elem => elem.Id === Number(this.EntryId));
		// this.globals.projeler[index] = data;
		this.showSuccess("Dosya eklendi.");
		this.modalService.dismissAll('');
		this.selectedFile = null;
		this.isLoading = false;
		// this.GetData();
		// this.DataYukle();
		this.PageData.Logo = data.filename;
		this.PageData['Adim2Durum'] = false;
	}

	onErrorItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
		this.showError("Dosya yükleme iptal edildi");
		this.modalService.dismissAll('');
		this.isLoading = false;
		let error = JSON.parse(response); //error server response
	}

	DataYukle() {
		var result = this.globals.projeler.filter(function (elem, i, rep) {
			return elem.Id === this;
		}, parseInt(this.EntryId));
		if (result.length) {
			this.PageData = JSON.parse(JSON.stringify(result[0]));
			if (this.PageData.EBook_Onay !== 2) {
				this.zone.run(() => {
					this.router.navigateByUrl('/giden');
				});
			}
			this.OrgData = JSON.parse(JSON.stringify(result[0]));
			let that = this;
			this.IlkSurdurulebilirlik = this.PageData['Surdurulebilir'];
			this.SonSurdurulebilirlik = this.PageData['Surdurulebilir'];

			this.Yil = this.PageData['Yil'];
			this.GlobalYonetim = this.globals.YilaGoreGlobalYonetimAl(this.Yil);
			this.Kategoriler = this.globals.YilaGoreKategoriAl(this.Yil);

			this.Kategoriler.forEach(element => {
				if (element.Kategori == this.PageData.Kategori) {
					this.TempSubKategoriler = element.SubKategoriler;
				}
			});

			this.Yuklendi = true;
			console.log(this.PageData)
		}
	}


	showSuccess(mesaj: string) {
		this.toastr.success(mesaj, 'Tebrikler!');
	}

	showError(mesaj: string) {
		this.toastr.error(mesaj, 'Hata!');
	}

	showWarning(mesaj: string) {
		this.toastr.warning(mesaj, 'Dikkat!');
	}

	showInfo(mesaj: string) {
		this.toastr.info(mesaj);
	}

	AdimDegistir(sayfa: number) {
		if (typeof sayfa !== 'undefined') {


			// let hata = false;

			// if (this.PageData.Ozet.trim().length === 0) {
			// 	this.showWarning('Marka Adı Girmelisiniz.');
			// 	hata = true;
			// 	return;
			// }

			// const UpdateData = {
			// 	'Ozet': this.PageData.Ozet,
			// 	'Id': this.EntryId
			// }

			// const params = new HttpParams();
			// let headers = new HttpHeaders();
			// headers = headers.append('Accept', 'application/json, text/javascript, */*; q=0.01');
			// headers = headers.append('Content-Type', 'application/json');
			// headers = headers.append('Authorization', 'Bearer ' + this.globals.User.Token);

			// this.http.post('https://api.effieturkiye.org/ProjeEbookKunye', UpdateData, { params, headers }).subscribe(
			// 	(response) => {
			// 		const index = this.globals.projeler.findIndex(elem => elem.Id === Number(this.EntryId));
			// 		this.globals.projeler[index] = response;
			// 		this.showSuccess('İkinci Adım Kaydedildi, yönlendiriliyorsunuz.');
			// 		this.messageService.sendMessage('ProjeGuncellendi');
			// 		this.router.navigateByUrl('/editentry/' + this.EntryId + '/' + sayfa);
			// 	},
			// 	error => {
			// 		//  console.log('Error', error)
			// 		this.showError('Bir hata oluştu, Lütfen tekrar deneyiniz');
			// 	}
			// );

			this.router.navigateByUrl('/editentry/' + this.EntryId + '/' + sayfa);
		}
		else {
			this.showError('Bir hata oluştu.');
		}
	}

	ReklamverenYetkiliSil(Index) {
		if (this.PageData['Adim2'].Reklamveren.Yetkililer.length === 1) {
			this.showWarning('En az 1 adet Reklamveren Yetkilisi Girmelisiniz.')
			return;
		}
		else {
			this.PageData['Adim2'].Reklamveren.Yetkililer.splice(Index, 1);
			this.showSuccess('Reklamveren Yetkilisi silindi.');
		}
	}

	ReklamverenYeniModalSave() {
		if (this.YeniReklamverenYetkiliAdi.trim().length === 0) {
			this.showWarning('Yetkili Adı Girmelisiniz.');
			return;
		}
		if (this.YeniReklamverenYetkiliEposta.trim().length === 0) {
			this.showWarning('Yetkili E-Posta Adresi Girmelisiniz.');
			return;
		}
		if (!this.validateEmail(this.YeniReklamverenYetkiliEposta.trim())) {
			this.showWarning('Geçerli Yetkili E-Posta Adresi Girmelisiniz.');
			return;
		}

		var temp = {};
		temp['Ad'] = this.YeniReklamverenYetkiliAdi.trim();
		temp['Eposta'] = this.YeniReklamverenYetkiliEposta.trim();
		this.PageData['Adim2'].Reklamveren.Yetkililer.push(temp);
		this.showSuccess('Reklamveren Yetkilisi eklendi.');
		this.modalService.dismissAll('');
		temp = null;
	}

	BasvuranAjansYetkiliSil(Index) {
		if (this.PageData['Adim2'].BasvuranAjans.Yetkililer.length === 1) {
			this.showWarning('En az 1 adet Basvuran Ana Ajans Yetkilisi Girmelisiniz.');
			return;
		}
		else {
			this.PageData['Adim2'].BasvuranAjans.Yetkililer.splice(Index, 1);
			this.showSuccess('Başvuran Ana Ajans Yetkilisi silindi.');
		}
	}
	BasvuranAjansYeniModalSave() {
		if (this.YeniBasvuranAjansYetkiliAdi.trim().length === 0) {
			this.showWarning('Yetkili Adı Girmelisiniz.');
			return;
		}
		if (this.YeniBasvuranAjansYetkiliEposta.trim().length === 0) {
			this.showWarning('Yetkili E-Posta Adresi Girmelisiniz.');
			return;
		}
		if (!this.validateEmail(this.YeniBasvuranAjansYetkiliEposta.trim())) {
			this.showWarning('Geçerli Yetkili E-Posta Adresi Girmelisiniz.');
			return;
		}
		var temp = {};
		temp['Ad'] = this.YeniBasvuranAjansYetkiliAdi.trim();
		temp['Eposta'] = this.YeniBasvuranAjansYetkiliEposta.trim();
		this.PageData['Adim2'].BasvuranAjans.Yetkililer.push(temp);
		this.showSuccess('Başvuran Ana Ajans Yetkilisi eklendi.');
		this.modalService.dismissAll('');
		temp = null;
	}

	KatkidaBulunanAjansSil(Index) {
		this.PageData['Adim2'].KatkidaBulunanAjanslar.splice(Index, 1);
		this.showSuccess('Katkıda Bulunan Ajans silindi.');
	}
	KatkidaBulunanYeniModalSave() {
		if (this.YeniKatkidaBulunanAjans.trim().length === 0) {
			this.showWarning('Katkıda Bulunan Ajans Adı Girmelisiniz.');
			return;
		}
		this.PageData['Adim2'].KatkidaBulunanAjanslar.push(this.YeniKatkidaBulunanAjans.trim());
		this.showSuccess('Katkıda Bulunan Ajans eklendi.');
		this.modalService.dismissAll('');
	}

	validateEmail(email) {
		var re = /\S+@\S+\.\S+/;
		return re.test(email);
	}

	phoneMask(event) {
		var num = event.replace(/\D/g, '');
		num = num.replace('(', '');
		num = num.replace(')', '');
		num = num.replace('-', '');

		var yeni = '';
		if (num.substring(0, 1) === '0') {
			this.PageData['Adim2'].FormuDolduran.Tel = yeni;
			this.showError('0 ile başlayamaz');
			return;
		}
		if (num.trim().length < 1) {
			yeni = '';
		}
		else if (num.trim().length >= 1 && num.trim().length < 4) {
			yeni = '(' + num.substring(0, 3);
		}
		else if (num.trim().length >= 4 && num.trim().length < 4) {
			yeni = '(' + num.substring(0, 3) + ') ';
		}
		else if (num.trim().length >= 4 && num.trim().length < 7) {
			yeni = '(' + num.substring(0, 3) + ') ' + num.substring(3, 6);
		}
		else if (num.trim().length >= 7 && num.trim().length < 9) {
			yeni = '(' + num.substring(0, 3) + ') ' + num.substring(3, 6) + '-' + num.substring(6, 8);
		}
		else if (num.trim().length >= 9 && num.trim().length < 11) {
			yeni = '(' + num.substring(0, 3) + ') ' + num.substring(3, 6) + '-' + num.substring(6, 8) + '-' + num.substring(8, 10);
		}
		else {
			yeni = '(' + num.substring(0, 3) + ') ' + num.substring(3, 6) + '-' + num.substring(6, 8) + '-' + num.substring(8, 10);
		}

		this.PageData['Adim2'].FormuDolduran.Tel = yeni;

	}

	UploadModalOpen(content) {
		this.selectedFile = null;
		let ngbModalOptions: NgbModalOptions = {
			backdrop: 'static',
			keyboard: false,
			size: 'lg'
		};

		this.modalService.open(content, ngbModalOptions).result.then((result) => {
		}, (reason) => {
		});
	}

	FileChange(event: any): void {
		this.selectedFile = event.target.files[0];
		if (this.selectedFile.size > 308908710) {
			this.showWarning("Dosya boyutu 300 mb dan büyük.");
			this.modalService.dismissAll('');
			this.selectedFile = null;
			this.uploader.clearQueue();
		}
	}

	DosyaModalSave() {
		if (this.selectedFile === null) {
			this.showWarning("Dosya Seçiniz.");
			return;
		}
		this.isLoading = true;
		this.uploader.options.additionalParameter = {
			Type: this.selectedFile.type,
			Boyut: this.selectedFile.size
		};
		this.uploader.uploadAll();
	}

}
