import { Component, OnInit, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Globals } from '../providers/globals/globals';
import { HttpClient, HttpHandler, HttpRequest, HttpParams, HttpHeaders } from '@angular/common/http';

import { Subscription } from 'rxjs/Subscription';
import { MessageServiceService } from '../services/message-service.service';

@Component({
	selector: 'app-viewentryadim2',
	templateUrl: './viewentryadim2.component.html',
	styleUrls: ['./viewentryadim2.component.css']
})
export class ViewentryAdim2Component implements OnInit {
	GlobalYonetim: any;
	subscription: Subscription;
	EntryId;
	PageData: any;
	OrgData: any;
	Kategoriler: any;
	IlkSurdurulebilirlik = false;
	SonSurdurulebilirlik = false;
	Yuklendi = false;
	KatkidaBulunanAjanslarYeni = '';
	TempSubKategoriler: any;
	Yil = '';


	YeniReklamverenYetkiliAdi = '';
	YeniReklamverenYetkiliEposta = '';

	YeniBasvuranAjansYetkiliAdi = '';
	YeniBasvuranAjansYetkiliEposta = '';
	YeniKatkidaBulunanAjans = '';

	ModalTitle: string = '';
	ModalData: Object = [];
	constructor(
		private http: HttpClient,
		private route: ActivatedRoute,
		private router: Router,
		private toastr: ToastrService,
		private zone: NgZone,
		private globals: Globals,
		private messageService: MessageServiceService,
	) {

	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	ngOnInit() {
		this.route.paramMap.subscribe(params => {
			this.EntryId = params.get('EntryId');
		});

		if (this.globals.Yonetim.Yuklendi === true) {
			this.GlobalYonetim = this.globals.YilaGoreGlobalYonetimAl(this.Yil);
			this.Kategoriler = this.globals.YilaGoreKategoriAl(this.Yil);
			window.scrollTo(0, 0);
			this.DataYukle();
		}

		this.subscription = this.messageService.getMessage().subscribe(message => {
			if (message.text === 'Yonetim') {
				this.GlobalYonetim = this.globals.YilaGoreGlobalYonetimAl(this.Yil);
				this.Kategoriler = this.globals.YilaGoreKategoriAl(this.Yil);
				window.scrollTo(0, 0);
				this.DataYukle();
			}
		});
	}

	DataYukle() {
		var result = this.globals.projeler.filter(function (elem, i, rep) {
			return elem.Id === this;
		}, parseInt(this.EntryId));
		if (result.length) {
			this.PageData = JSON.parse(JSON.stringify(result[0]));
			if (this.PageData.EBook_Onay !== 3) {
				this.zone.run(() => {
					this.router.navigateByUrl('/giden');
				});
			}
			this.OrgData = JSON.parse(JSON.stringify(result[0]));

			this.Yil = this.PageData['Yil'];
			this.GlobalYonetim = this.globals.YilaGoreGlobalYonetimAl(this.Yil);
			this.Kategoriler = this.globals.YilaGoreKategoriAl(this.Yil);

			this.IlkSurdurulebilirlik = this.PageData['Surdurulebilir'];
			this.SonSurdurulebilirlik = this.PageData['Surdurulebilir'];
			// this.Kategoriler = this.globals.Yonetim.Adim1;
			this.Kategoriler.forEach(element => {
				if (element.Kategori == this.PageData.Kategori) {
					this.TempSubKategoriler = element.SubKategoriler;
				}
			});
			this.Yuklendi = true;
		}
	}

	showSuccess(mesaj: string) {
		this.toastr.success(mesaj, 'Tebrikler!');
	}

	showError(mesaj: string) {
		this.toastr.error(mesaj, 'Hata!');
	}

	showWarning(mesaj: string) {
		this.toastr.warning(mesaj, 'Dikkat!');
	}

	showInfo(mesaj: string) {
		this.toastr.info(mesaj);
	}

	AdimDegistir(sayfa: number) {
		if (typeof sayfa !== 'undefined') {
			this.router.navigateByUrl('/viewentry/' + this.EntryId + '/' + sayfa);
		}
		else {
			this.showError('Bir hata oluştu.');
		}
	}

}
