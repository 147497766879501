<section id="IcerikContainer">
    <div class="dev-wrapper dev-entrant-home dev-wrapper-entry-home dev-flex-container IcerikSubContainer">
        <article id="content" class="dev-flex-child">
            <div class="wizard-v4-content">
                <div class="wizard-form">
                    <div class="form-register gonderilecek">
                        <div id="form-total" role="application" class="wizard clearfix">
                            <div class="content clearfix">
                                <table width=600>
                                    
                                        <tr *ngFor="let item of ProjeListesi; let i = index;">
                                            <td>{{item}}</td>
                                            <td>
                                                <button (click)="downloadFile(item)">4 PDF İndir</button>
                                            </td>
                                            <td>
                                                <button (click)="downloadFileDosyalar(item)">Dosyalar PDF İndir</button>
                                                <!-- <a target="_blank" href="https://pdfebook.effieturkiye.org/pdfler/{{item}}/{{item}}_dosyalar.pdf">Dosyalar PDF Aç</a> -->
                                            </td>
                                            <td>
                                                <button (click)="DosyaSec(item)">Oluştur</button>
                                            </td>
                                        </tr>
                                    
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </article>
    </div>
</section>
