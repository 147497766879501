<section id="TopNav">
    <nav>
        <!-- <a *ngIf="loggedin == true" [routerLink]="['/newentry']" title="New Entry"><span>New Entry</span></a> -->
        <!-- <a *ngIf="loggedin == true" [routerLink]="['/account']" title="Account"><span>Account</span></a> -->
        <!-- <a *ngIf="loggedin == true" href="" title="Logout"><span>Log Out</span></a> -->
    </nav>

    <header>
        <a *ngIf="globals.User.Token.length > 0" [routerLink]="['/giden']"><img src="assets/blackbackground.png"></a>
        <a *ngIf="globals.User.Token.length == 0" [routerLink]="['/']"><img src="assets/blackbackground.png"></a>
    </header>
</section>