import { Component, OnInit, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Globals } from '../providers/globals/globals';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Subscription } from 'rxjs/Subscription';
import { MessageServiceService } from '../services/message-service.service';
import { HttpClient, HttpHandler, HttpRequest, HttpParams, HttpHeaders } from '@angular/common/http';

@Component({
	selector: 'app-editentryadim9',
	templateUrl: './editentryadim9.component.html',
	styleUrls: ['./editentryadim9.component.css']
})
export class EditentryAdim9Component implements OnInit {
	subscription: Subscription;
	GlobalYonetim: any;
	EntryId;
	PageData: any;
	Yuklendi = false;
	isLoading = false;
	PDFHtml3 = '';
	Sorular: any = [];
	Dosyalar = [];
	Yil = '';

	DosyaSilSwal = {
		title: 'Emin misiniz?',
		text: "Başvurunuzu Tamamlamak Üzeresiniz. Gönderdikten sonra değişiklik yapamayacaksınız.",
		type: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#0CC27E',
		cancelButtonColor: '#FF586B',
		confirmButtonText: 'Evet, gönder!',
		cancelButtonText: 'İptal'
	};

	constructor(

		private route: ActivatedRoute,
		private router: Router,
		private toastr: ToastrService,
		private globals: Globals,
		private messageService: MessageServiceService,
		private zone: NgZone,
		private http: HttpClient,
	) {

	}

	hasBaseDropZoneOver = false;
	fileOverBase(e: any): void {
		this.hasBaseDropZoneOver = e;
	}

	ngOnDestroy() {


		this.subscription.unsubscribe();
	}

	ngOnInit() {
		this.route.paramMap.subscribe(params => {
			this.EntryId = params.get('EntryId');
		});

		if (this.globals.Yonetim.Yuklendi === true) {
			this.GlobalYonetim = this.globals.YilaGoreGlobalYonetimAl(this.Yil);
			window.scrollTo(0, 0);
			this.DataYukle();
		}

		this.subscription = this.messageService.getMessage().subscribe(message => {
			if (message.text === 'Yonetim') {
				this.GlobalYonetim = this.globals.YilaGoreGlobalYonetimAl(this.Yil);
				this.DataYukle();
			}
		});
	}

	DataYukle() {
		var result = this.globals.projeler.filter(function (elem, i, rep) {
			return elem.Id === this;
		}, parseInt(this.EntryId));
		if (result.length) {
			// this.PageData = JSON.parse(JSON.stringify(result[0]));
			this.PageData = result[0];
			this.PageData.GuncellemeTarihi = this.PageData.GuncellemeTarihi.replace(' ', 'T');
			this.PageData.Adim9Tarih = this.PageData.Adim9Tarih.replace(' ', 'T');

			this.Dosyalar = [];
			this.PageData['Adim8'].forEach(element => {
				this.Dosyalar.push(element);
			});
			// if (this.PageData.Adim11Durum === 1) {
			// 	this.zone.run(() => {
			// 		this.router.navigateByUrl('/dashboard');
			// 	});
			// }
			this.Yil = this.PageData['Yil'];
			this.GlobalYonetim = this.globals.YilaGoreGlobalYonetimAl(this.Yil);
			
			this.Yuklendi = true;
			if (this.PageData.Surdurulebilir === 1) {
				this.Sorular = this.globals.SorularSurdurulebilir;
			}
			else {
				this.Sorular = this.globals.Sorular;
			}
			// if (this.PageData.EBook_Onay !== 2) {
			// 	this.zone.run(() => {
			// 		this.router.navigateByUrl('/giden');
			// 	});
			// }
			this.Yuklendi = true;
		}
	}

	HtmlOlustur() {
		this.PDFHtml3 = '';
		this.PDFHtml3 += '<style>p{margin-top:0px; margin-bottom: 0px;padding-top:0px; padding-bottom: 0px;}img{max-width:100%;}</style>';
		// this.PDFHtml3 = '<table style="width: 580px; height: 40px; background-color: #000; border-collapse: collapse;" align="center" border = "0">'
		this.PDFHtml3 += '<table style="width: 580px; height: 0px; background-color: #000; border-collapse: collapse; display:none;" align="center" border = "0">'
		this.PDFHtml3 += '<tr>'
		this.PDFHtml3 += '<td style="width:125px; height: 40px;" ><img style="width:100px; height: 40px;" src="http://api.effieturkiye.org/storage/blackbackground.png" /></td>'
		this.PDFHtml3 += '<td valign = "middle" style = "width:380px; height: 40px; vertical-align: middle; color:#fff; font-size: 16px; line-height: 60px; text-align:center;">Effie '+ this.PageData['Yil'] +' Başvuru Formu</td>'
		this.PDFHtml3 += '<td style = "width:125px; height: 40px; vertical-align: middle; color:#fff; font-size: 16px; text-align:center;">&nbsp;</td>'
		this.PDFHtml3 += '</tr>'
		this.PDFHtml3 += '</table>';
		this.PDFHtml3 += '<img style="height: 0px; display: none;" src="http://api.effieturkiye.org/storage/Effie_PDF_LOGO_LEFT2.png" />';
		this.PDFHtml3 += '<img style="height: 0px; display: none;" src="http://api.effieturkiye.org/storage/Effie_PDF_LOGO_RIGHT2.png" />';
		this.PDFHtml3 += '<img style="height: 0px; display: none;" src="http://api.effieturkiye.org/storage/logolar/' + this.PageData.Logo + '" />';
		// this.PDFHtml3 += '<br>'

		// this.PDFHtml3 = '';
		this.PDFHtml3 += '<div class="page" style="position: relative;display: block;margin: 0px;height: 100%;width: 100%;background: white;page-break-after:always;overflow: hidden;">';
		this.PDFHtml3 += '<div style="width: 100%; color: #b1985c; text-align:center; font-size:18px; font-weight: 600; padding-top: 40px; padding-bottom: 20px;">' + this.PageData['KampanyaAdi'] + '</div>';
		// this.PDFHtml3 += '<div style="width: 100%; text-align:center; font-size:12px;">' + this.PageData['Ozet'] + '</div>';
		this.PDFHtml3 += '<div style="width: 100%; text-align:center; font-size:12px;">';

		this.PDFHtml3 += '<br>'

		this.PDFHtml3 += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
		this.PDFHtml3 += '<tbody>'
		this.PDFHtml3 += '<tr><td style="color:#fff; font-size: 14px; text-align:center;">Özet</td></tr>'
		this.PDFHtml3 += '</tbody>'
		this.PDFHtml3 += '</table>'

		this.PDFHtml3 += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
		this.PDFHtml3 += '<tbody>'
		this.PDFHtml3 += '<tr>'
		this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">Kampanya Başlangıç Tarihi:</td>'
		this.PDFHtml3 += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData.Adim3.BasGun + '.' + this.AyDondur(this.PageData.Adim3.BasAy) + '.' + this.PageData.Adim3.BasYil + '</td>'
		this.PDFHtml3 += '</tr>'
		this.PDFHtml3 += '<tr>'
		this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">Kampanya Bitiş Tarihi:</td>'
		if (this.PageData.Adim3.DevamEdiyor == 1) {
			this.PDFHtml3 += '<td  style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">Devam Ediyor</td>'
		}
		else {
			this.PDFHtml3 += '<td  style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData.Adim3.BitGun + '.' + this.AyDondur(this.PageData.Adim3.BitAy) + '.' + this.PageData.Adim3.BitYil + '</td>'
		}

		this.PDFHtml3 += '</tr>'
		this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">Zorluk:</td>'
		this.PDFHtml3 += '<td  style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData.Adim3.Satir2 + '</td>'
		this.PDFHtml3 += '</tr>'
		this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">İçgörü:</td>'
		this.PDFHtml3 += '<td  style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData.Adim3.Satir3 + '</td>'
		this.PDFHtml3 += '</tr>'
		this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">Büyük Fikir:</td>'
		this.PDFHtml3 += '<td  style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData.Adim3.Satir4 + '</td>'
		this.PDFHtml3 += '</tr>'
		this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">Fikrin Hayata Geçmesi:</td>'
		this.PDFHtml3 += '<td  style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData.Adim3.Satir5 + '</td>'
		this.PDFHtml3 += '</tr>'
		this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;" colspan="2">Pazarlama iletişim etkinliği kapsamında bu vakanın neden bu kategoride ödül alması gerektiğini düşünüyorsunuz? Vakanızı elde ettiğiniz sonuçların zorluğu aşmakla ilişkisini kurarak özetleyiniz. İki ayrı kategoriden giren kampanyalar için bu özet farklı olmalıdır.</td>'
		this.PDFHtml3 += '</tr>'
		this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml3 += '<td  style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;" colspan="2">' + this.PageData.Adim3.Satir8 + '</td>'
		this.PDFHtml3 += '</tr>'
		this.PDFHtml3 += '</tbody>'
		this.PDFHtml3 += '</table>'

		this.PDFHtml3 += '</div>';
		this.PDFHtml3 += '</div>';

		this.PDFHtml3 += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
		this.PDFHtml3 += '<tbody>'
		this.PDFHtml3 += '<tr><td style="color:#fff; font-size: 14px; text-align:center;">Künye</td></tr>'
		this.PDFHtml3 += '</tbody>'
		this.PDFHtml3 += '</table>'

		this.PDFHtml3 += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
		this.PDFHtml3 += '<tbody>'
		this.PDFHtml3 += '<tr>'
		this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">Marka Adı:</td>'
		this.PDFHtml3 += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['MarkaAdi'] + '</td>'
		this.PDFHtml3 += '</tr>'
		this.PDFHtml3 += '<tr>'
		this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">Marka İli:</td>'
		this.PDFHtml3 += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['MarkaIl'] + '</td>'
		this.PDFHtml3 += '</tr>'
		this.PDFHtml3 += '<tr>'
		this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">Marka Ülkesi:</td>'
		this.PDFHtml3 += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['MarkaUlke'] + '</td>'
		this.PDFHtml3 += '</tr>'
		this.PDFHtml3 += '<tr>'
		this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">Kampanya Adı:</td>'
		this.PDFHtml3 += '<td  style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['KampanyaAdi'] + '</td>'
		this.PDFHtml3 += '</tr>'
		this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">Kategori Adı:</td>'
		this.PDFHtml3 += '<td  style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['Kategori'] + ' ' + this.PageData['SubKategori'] + '</td>'
		this.PDFHtml3 += '</tr>'
		this.PDFHtml3 += '</tbody>'
		this.PDFHtml3 += '</table>'
		this.PDFHtml3 += '<br>'

		this.PDFHtml3 += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
		this.PDFHtml3 += '<tbody>'
		this.PDFHtml3 += '<tr><td style="color:#fff; font-size: 14px; text-align:center;">Reklamveren</td></tr>'
		this.PDFHtml3 += '</tbody>'
		this.PDFHtml3 += '</table>'

		this.PDFHtml3 += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
		this.PDFHtml3 += '<tbody>'
		this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">Reklamveren Adı:</td>'
		this.PDFHtml3 += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData.ReklamverenAdi + '</td>'
		this.PDFHtml3 += '</tr>'
		this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">Adresi:</td>'
		this.PDFHtml3 += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData.ReklamverenAdres + '</td>'
		this.PDFHtml3 += '</tr>'
		this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">İli:</td>'
		this.PDFHtml3 += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData.ReklamverenIl + '</td>'
		this.PDFHtml3 += '</tr>'
		this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">Ülkesi:</td>'
		this.PDFHtml3 += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData.ReklamverenUlke + '</td>'
		this.PDFHtml3 += '</tr>'
		this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">Network & Holding:</td>'
		this.PDFHtml3 += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData.ReklamverenHolding + '</td>'
		this.PDFHtml3 += '</tr>'
		this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">Web Sitesi:</td>'
		this.PDFHtml3 += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;"><a href="' + this.PageData.ReklamverenWeb + '">' + this.PageData.ReklamverenWeb + '</a></td>'
		this.PDFHtml3 += '</tr>'
		this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">Yetkililer:</td>'
		var ReklamverenYetkililer = '';
		for (var i = 0; i < this.PageData.ReklamverenYetkililer.length; i++) {
			if (i > 0) {
				ReklamverenYetkililer = ReklamverenYetkililer + "<br>";
			}
			ReklamverenYetkililer = ReklamverenYetkililer + this.PageData.ReklamverenYetkililer[i]['AdSoyad'];
		}
		this.PDFHtml3 += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + ReklamverenYetkililer + '</td>'
		this.PDFHtml3 += '</tr>'
		this.PDFHtml3 += '</tbody>'
		this.PDFHtml3 += '</table>'
		this.PDFHtml3 += '<br>'

		this.PDFHtml3 += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
		this.PDFHtml3 += '<tbody>'
		this.PDFHtml3 += '<tr>'
		this.PDFHtml3 += '<td style="color:#fff; font-size: 14px; text-align:center;">Başvuran Ana Ajans</td>'
		this.PDFHtml3 += '</tr>'
		this.PDFHtml3 += '</tbody>'
		this.PDFHtml3 += '</table>'
		this.PDFHtml3 += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
		this.PDFHtml3 += '<tbody>'
		this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">Adı:</td>'
		this.PDFHtml3 += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData.BasvuranAjansAdi + '</td>'
		this.PDFHtml3 += '</tr>'
		this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">Adresi:</td>'
		this.PDFHtml3 += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData.BasvuranAjansAdres + '</td>'
		this.PDFHtml3 += '</tr>'
		this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">İli:</td>'
		this.PDFHtml3 += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData.BasvuranAjansIl + '</td>'
		this.PDFHtml3 += '</tr>'
		this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">Ülkesi:</td>'
		this.PDFHtml3 += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData.BasvuranAjansUlke + '</td>'
		this.PDFHtml3 += '</tr>'
		this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">Bağlı Olduğu Network:</td>'
		this.PDFHtml3 += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData.BasvuranAjansNetwork + '</td>'
		this.PDFHtml3 += '</tr>'
		this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">Bağlı Olduğu Ajans Holding:</td>'
		this.PDFHtml3 += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData.BasvuranAjansHolding + '</td>'
		this.PDFHtml3 += '</tr>'
		this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">Web Sitesi:</td>'
		this.PDFHtml3 += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;"><a href="' + this.PageData.BasvuranAjansWeb + '">' + this.PageData.BasvuranAjansWeb + '</a></td>'
		this.PDFHtml3 += '</tr>'
		this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">Yetkililer:</td>'
		var BasvuranAjansYetkililer = '';
		for (var i = 0; i < this.PageData.BasvuranAjansYetkililer.length; i++) {
			if (i > 0) {
				BasvuranAjansYetkililer = BasvuranAjansYetkililer + "<br>";
			}
			BasvuranAjansYetkililer = BasvuranAjansYetkililer + this.PageData.BasvuranAjansYetkililer[i]['AdSoyad'];
		}
		this.PDFHtml3 += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + BasvuranAjansYetkililer + '</td>'
		this.PDFHtml3 += '</tr>'
		this.PDFHtml3 += '</tbody>'
		this.PDFHtml3 += '</table>'
		this.PDFHtml3 += '<br>'

		for (var i = 0; i < this.PageData.KatkidaBulunanAjanslar.length; i++) {
			this.PDFHtml3 += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
			this.PDFHtml3 += '<tbody>'
			this.PDFHtml3 += '<tr>'
			this.PDFHtml3 += '<td style="color:#fff; font-size: 14px; text-align:center;">Katkıda Bulunan Ajans ' + (i + 1) + '</td>'
			this.PDFHtml3 += '</tr>'
			this.PDFHtml3 += '</tbody>'
			this.PDFHtml3 += '</table>'


			this.PDFHtml3 += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
			this.PDFHtml3 += '<tbody>'
			this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
			this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">Adı:</td>'
			this.PDFHtml3 += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData.KatkidaBulunanAjanslar[i]['Ajans'] + '</td>'
			this.PDFHtml3 += '</tr>'
			this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
			this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">İli:</td>'
			this.PDFHtml3 += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData.KatkidaBulunanAjanslar[i]['Il'] + '</td>'
			this.PDFHtml3 += '</tr>'
			this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
			this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">Ülkesi:</td>'
			this.PDFHtml3 += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData.KatkidaBulunanAjanslar[i]['Ulke'] + '</td>'
			this.PDFHtml3 += '</tr>'
			this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
			this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">Bağlı Olduğu Network:</td>'
			this.PDFHtml3 += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData.KatkidaBulunanAjanslar[i]['Network'] + '</td>'
			this.PDFHtml3 += '</tr>'
			this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
			this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">Bağlı Olduğu Ajans Holding:</td>'
			this.PDFHtml3 += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData.KatkidaBulunanAjanslar[i]['Holding'] + '</td>'
			this.PDFHtml3 += '</tr>'
			this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
			this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">Web Sitesi:</td>'
			this.PDFHtml3 += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;"><a href="' + this.PageData.KatkidaBulunanAjanslar[i]['Web'] + '">' + this.PageData.KatkidaBulunanAjanslar[i]['Web'] + '</a></td>'
			this.PDFHtml3 += '</tr>'
			this.PDFHtml3 += '</tbody>'
			this.PDFHtml3 += '</table>'
			this.PDFHtml3 += '<br>'
		}

		// this.PDFHtml3 += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
		// this.PDFHtml3 += '<tbody>'
		// this.PDFHtml3 += '<tr>'
		// this.PDFHtml3 += '<td style="color:#fff; font-size: 14px; text-align:center;">Formu Dolduran</td>'
		// this.PDFHtml3 += '</tr>'
		// this.PDFHtml3 += '</tbody>'
		// this.PDFHtml3 += '</table>'
		// this.PDFHtml3 += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
		// this.PDFHtml3 += '<tbody>'
		// this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
		// this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">Adı, Soyadı:</td>'
		// this.PDFHtml3 += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData.FormuDolduranAdi + '</td>'
		// this.PDFHtml3 += '</tr>'
		// this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
		// this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">Görevi:</td>'
		// this.PDFHtml3 += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData.FormuDolduranGorevi + '</td>'
		// this.PDFHtml3 += '</tr>'
		// this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
		// this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">Telefon:</td>'
		// this.PDFHtml3 += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData.FormuDolduranTelefon + '</td>'
		// this.PDFHtml3 += '</tr>'
		// this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
		// this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">E-Posta:</td>'
		// this.PDFHtml3 += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;"><a href="mailto:' + this.PageData.FormuDolduranEposta + '">' + this.PageData.FormuDolduranEposta + '</a></td>'
		// this.PDFHtml3 += '</tr>'
		// this.PDFHtml3 += '</tbody>'
		// this.PDFHtml3 += '</table>'
		// this.PDFHtml3 += '<br>';
		
		this.PDF3Icerik();


		this.PDFHtml3 += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
		this.PDFHtml3 += '<tbody>'
		this.PDFHtml3 += '<tr><td style="color:#fff; font-size: 14px; text-align:center;">Dosyalar</td></tr>'
		this.PDFHtml3 += '</tbody>'
		this.PDFHtml3 += '</table>'
		this.PDFHtml3 += '<table style="width: 580px; background-color:#fff;border: 1px solid #b1985c;" align="center" border="0">'
		this.PDFHtml3 += '<tbody>'
		this.Dosyalar.forEach((item: any) => {
			this.PDFHtml3 += '<tr style="font-size: 10px; background-color:#fff;border: 1px solid #b1985c;">';
			if (item.Type !== 'Link') {
				this.PDFHtml3 += '<td><a style="font-size: 10px;" target="_blank" href="https://api.effieturkiye.org/storage/dosyalar/' + this.EntryId + '/' + encodeURIComponent(item.DosyaAdi) + '">' + item.DosyaAdi + '</td>';
				this.PDFHtml3 += '<td style="font-size: 10px;">' + this.DosyaType(item.Type) + ' (' + this.formatBytes(item.Boyut) + ')</td>';
			}
			else {
				this.PDFHtml3 += '<td><a style="font-size: 10px;" href="' + item.DosyaAdi + '" target="_blank">' + item.DosyaAdi + '</a></td>';
				this.PDFHtml3 += '<td style="font-size: 10px;">' + this.DosyaType(item.Type) + '</td>';
			}
			this.PDFHtml3 += '</tr>';
		});

		this.PDFHtml3 += '</tbody>'
		this.PDFHtml3 += '</table>'
		this.PDFHtml3 += '<br>';
		this.SayfaOlustur();
	}

	PDF3Icerik() {
		var index = -1;
		this.PageData.SoruCevap.forEach(element => {
			index++
			if (element.Cevap.Type === 'SoruText') {
				this.PDFHtml3 += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
				this.PDFHtml3 += '<tbody>'
				this.PDFHtml3 += '<tr>'
				this.PDFHtml3 += '<td style="color:#fff; font-size: 14px; text-align:center;">' + this.Sorular[index].Soru.Soru + '</td>'
				this.PDFHtml3 += '</tr>'
				this.PDFHtml3 += '</tbody>'
				this.PDFHtml3 += '</table>'
				var cevap = '';
				if (element.Cevap.Cevap !== null) {
					// cevap = element.Cevap.Cevap.replace(/<img /g, '<img style="width:580px;" ')
					cevap = element.Cevap.Cevap.replace(/<p><img /g, '<p style="text-align: center;"><img style="max-width:480px; text-align: center;" ');
					cevap = cevap.trim();
					cevap = cevap.replace(/src="https/g, 'src="http');
				}
				this.PDFHtml3 += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
				this.PDFHtml3 += '<tbody>'
				this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
				this.PDFHtml3 += '<td style="width: 580px; font-size: 12px; background-color:#fff;border: 1px solid #b1985c;">' + cevap + '</td>'
				this.PDFHtml3 += '</tr>'
				this.PDFHtml3 += '</tbody>'
				this.PDFHtml3 += '</table>'
				this.PDFHtml3 += '<br>'
			}
			else if (element.Cevap.Type === 'Radio') {
				this.PDFHtml3 += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
				this.PDFHtml3 += '<tbody>'
				this.PDFHtml3 += '<tr>'
				this.PDFHtml3 += '<td style="color:#fff; font-size: 14px; text-align:center;">' + this.Sorular[index].Soru.Soru + '</td>'
				this.PDFHtml3 += '</tr>'
				this.PDFHtml3 += '</tbody>'
				this.PDFHtml3 += '</table>'

				this.PDFHtml3 += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
				this.PDFHtml3 += '<tbody>'
				this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
				var Radio = '';
				for (var i = 0; i < element.Cevap.Radios.length; i++) {
					if (element.Cevap.Radios[i].Value === true) {
						Radio = element.Cevap.Radios[i].Name;
					}
				}
				this.PDFHtml3 += '<td style="width: 580px; font-size: 12px; background-color:#fff;border: 1px solid #b1985c;">' + Radio + '</td>'
				this.PDFHtml3 += '</tr>'
				this.PDFHtml3 += '</tbody>'
				this.PDFHtml3 += '</table>'
				this.PDFHtml3 += '<br>'
			}
			else if (element.Cevap.Type === 'CheckBox') {
				this.PDFHtml3 += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
				this.PDFHtml3 += '<tbody>'
				this.PDFHtml3 += '<tr>'
				this.PDFHtml3 += '<td style="color:#fff; font-size: 14px; text-align:center;">' + this.Sorular[index].Soru.Soru + '</td>'
				this.PDFHtml3 += '</tr>'
				this.PDFHtml3 += '</tbody>'
				this.PDFHtml3 += '</table>'

				this.PDFHtml3 += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
				this.PDFHtml3 += '<tbody>'
				this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
				var CheckBoxes = '';
				var say = 0;
				for (var i = 0; i < element.Cevap.CheckBoxes.length; i++) {
					if (element.Cevap.CheckBoxes[i]["Value"] === true) {
						if (say > 0) {
							CheckBoxes = CheckBoxes + "<br>";
						}
						if (element.Cevap.CheckBoxes[i]["Type"] === "CheckBox") {
							CheckBoxes = CheckBoxes + element.Cevap.CheckBoxes[i]["Name"];
						}
						else {
							CheckBoxes = CheckBoxes + "Diğer: " + element.Cevap.CheckBoxes[i]["Cevap"];
						}
						say++;
					}
				}
				this.PDFHtml3 += '<td style="width: 580px; font-size: 12px; background-color:#fff;border: 1px solid #b1985c;">' + CheckBoxes + '</td>'
				this.PDFHtml3 += '</tr>'
				this.PDFHtml3 += '</tbody>'
				this.PDFHtml3 += '</table>'
				this.PDFHtml3 += '<br>'
			}
			else if (element.Cevap.Type === 'MultiCheckBox') {
				this.PDFHtml3 += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
				this.PDFHtml3 += '<tbody>'
				this.PDFHtml3 += '<tr>'
				this.PDFHtml3 += '<td style="color:#fff; font-size: 14px; text-align:center;">' + this.Sorular[index].Soru.Soru + '</td>'
				this.PDFHtml3 += '</tr>'
				this.PDFHtml3 += '</tbody>'
				this.PDFHtml3 += '</table>'

				this.PDFHtml3 += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
				this.PDFHtml3 += '<tbody>'
				this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
				var CheckBoxes = '';
				for (var i = 0; i < element.Cevap.CheckBoxes.length; i++) {
					var temp = ""
					for (var x = 0; x < element.Cevap.CheckBoxes[i]["SubCheckBoxes"].length; x++) {
						if (element.Cevap.CheckBoxes[i]["SubCheckBoxes"][x]["Value"] === true) {

							if (temp.length > 0) {
								temp = temp + ", "
							}
							if (element.Cevap.CheckBoxes[i]["SubCheckBoxes"][x]["Type"] === "CheckBox") {
								temp = temp + element.Cevap.CheckBoxes[i]["SubCheckBoxes"][x]["Name"];
							}
							else {
								temp = temp + element.Cevap.CheckBoxes[i]["SubCheckBoxes"][x]["Name"] + ": " + element.Cevap.CheckBoxes[i]["SubCheckBoxes"][x]["Cevap"];
							}
						}
					}
					if (temp.length > 0) {
						if (i > 0) {
							CheckBoxes = CheckBoxes + "<br>";
						}
						CheckBoxes = CheckBoxes + "<b>" + element.Cevap.CheckBoxes[i]["Grup"] + ": </b>"
						CheckBoxes = CheckBoxes + temp;
					}
				}
				this.PDFHtml3 += '<td style="width: 580px; font-size: 12px; background-color:#fff;border: 1px solid #b1985c;">' + CheckBoxes + '</td>'
				this.PDFHtml3 += '</tr>'
				this.PDFHtml3 += '</tbody>'
				this.PDFHtml3 += '</table>'
				this.PDFHtml3 += '<br>'
			}
			else if (element.Cevap.Type === 'Kaynak') {
				this.PDFHtml3 += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
				this.PDFHtml3 += '<tbody>'
				this.PDFHtml3 += '<tr>'
				this.PDFHtml3 += '<td style="color:#fff; font-size: 14px; text-align:center;">' + this.Sorular[index].Soru.Soru + '</td>'
				this.PDFHtml3 += '</tr>'
				this.PDFHtml3 += '</tbody>'
				this.PDFHtml3 += '</table>'
				var cevap = '';
				if (element.Cevap.Cevap !== null) {
					cevap = element.Cevap.Cevap.replace(/<img /g, '<img style="width:580px;" ')
					cevap = cevap.trim()
					cevap = element.Cevap.Cevap.replace(/src="https/g, 'src="http');
					cevap = cevap.replace(".jpg?alt=media", "_600x1300.jpg?alt=media")
					cevap = cevap.replace(".png?alt=media", "_600x1300.jpg?alt=media")
					cevap = cevap.replace(".jpeg?alt=media", "_600x1300.jpg?alt=media")
					cevap = cevap.replace(".bmp?alt=media", "_600x1300.jpg?alt=media")
					cevap = cevap.replace(".gif?alt=media", "_600x1300.jpg?alt=media")
				}

				this.PDFHtml3 += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
				this.PDFHtml3 += '<tbody>'
				this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
				this.PDFHtml3 += '<td style="width: 580px; font-size: 12px; background-color:#fff;border: 1px solid #b1985c;">' + cevap + '</td>'
				this.PDFHtml3 += '</tr>'
				this.PDFHtml3 += '</tbody>'
				this.PDFHtml3 += '</table>'
				this.PDFHtml3 += '<br>'
			}
		});
	}

	SayfaOlustur() {

		this.showWarning("PDF Oluşturuluyor. Zaman alabilir lütfen bekleyiniz.");
		this.isLoading = true;
		var params = new HttpParams();
		var headers = new HttpHeaders()
			.append('Content-Type', 'application/json; charset=utf-8');
		// http://localhost:3000/
		this.http.post("https://pdfebook.effieturkiye.org/ebook", { ProjectId: this.EntryId.toString(), icerik: this.PDFHtml3, Surdurulebilir: this.PageData.Surdurulebilir, Logo: this.PageData.Logo }, {
			params: params,
			headers: headers
		})
			.subscribe(data => {
				this.isLoading = false;
				// console.log(data);
				this.messageService.sendMessage('ProjeGuncellendi');
				this.PageData.Adim8.Tarih = data['Tarih'].replace(' ', 'T');
				this.PageData.Adim8.SayfaSayisi = data['PageNumber'];
				let result = this.globals.projeler.filter(function (elem, i, rep) {
					return elem.Id === this;
				}, parseInt(this.EntryId));
				if (result.length) {
					result[0].Adim9TarihEbook = data['Tarih'].replace(' ', 'T');
					result[0].Adim9SayfaSayisi = data['PageNumber'];
					result[0].Adim9Durum = true;
				}

				this.showSuccess("PDF Oluşturuldu. Son Versiyonu indire basarak indirebilirsiniz.");
				this.DataYukle();
				// window.location.href = "http://localhost:3000/pdf/pdf/" + this.EntryId + "/1.pdf";
			},
				err => {
					this.isLoading = false;
					this.showError("Bir hata oluştu, Lütfen tekrar deneyiniz");
				});
	}

	showSuccess(mesaj: string) {
		this.toastr.success(mesaj, 'Tebrikler!');
	}

	showError(mesaj: string) {
		this.toastr.error(mesaj, 'Hata!');
	}

	showWarning(mesaj: string) {
		this.toastr.warning(mesaj, 'Dikkat!');
	}

	showInfo(mesaj: string) {
		this.toastr.info(mesaj);
	}

	AdimDegistir(sayfa: number) {
		this.router.navigateByUrl('/editentry/' + this.EntryId + '/' + sayfa);
	}

	BasvuruGonder() {
		const UpdateData = {
			Id: this.EntryId,
			EBook_Onay: 3,
			GuncellemeTarihi: new Date()
		}
		// console.log(JSON.stringify(UpdateData));

		const params = new HttpParams();
		let headers = new HttpHeaders();
		headers = headers.append('Accept', 'application/json, text/javascript, */*; q=0.01');
		headers = headers.append('Content-Type', 'application/json');
		headers = headers.append('Authorization', 'Bearer ' + this.globals.User.Token);

		this.http.post('https://api.effieturkiye.org/ProjeEbook', UpdateData, { params, headers }).subscribe(
			(response) => {
				const index = this.globals.projeler.findIndex(elem => elem.Id === Number(this.EntryId));
				this.globals.projeler[index] = response;
				this.messageService.sendMessage('ProjeGuncellendi');
				this.showSuccess("Son adım kaydedildi, yönlendiriliyorsunuz.");
				this.router.navigateByUrl('/giden');
			},
			error => {
				//  console.log('Error', error)
				this.showError('Bir hata oluştu, Lütfen tekrar deneyiniz');
			}
		);

	}

	AyDondur(ay: number) {
		if (ay === 0) {
			return "";
		}
		else if (ay === 1) {
			return "Ocak";
		}
		else if (ay === 2) {
			return "Şubat";
		}
		else if (ay === 3) {
			return "Mart";
		}
		else if (ay === 4) {
			return "Nisan";
		}
		else if (ay === 5) {
			return "Mayıs";
		}
		else if (ay === 6) {
			return "Haziran";
		}
		else if (ay === 7) {
			return "Temmuz";
		}
		else if (ay === 8) {
			return "Ağustos";
		}
		else if (ay === 9) {
			return "Eylül";
		}
		else if (ay === 10) {
			return "Ekim";
		}
		else if (ay === 11) {
			return "Kasım";
		}
		else if (ay === 12) {
			return "Aralık";
		}
	}

	formatBytes(bytes, decimals = 2) {
		if (bytes === 0) return '0 Bytes';

		const k = 1024;
		const dm = decimals < 0 ? 0 : decimals;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

		const i = Math.floor(Math.log(bytes) / Math.log(k));

		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	}

	DosyaType(text) {
		if (text === 'text/plain') {
			text = "txt"
		}
		else if (text === "application/msword") {
			text = "Word"
		} else if (text === "image/jpeg") {
			text = "Resim"
		}
		else if (text === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
			text = "Excel"
		}
		else if (text === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
			text = "Word"
		}
		else {

		}
		return text
	}
}
