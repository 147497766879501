import { Component, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { MessageServiceService } from './services/message-service.service';
import { Globals } from './providers/globals/globals';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import jwt_decode from "jwt-decode";
import { Location } from '@angular/common';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent {
	loggedin = false;
	SolGizli = false;
	message: any;
	subscription: Subscription;
	ProjeKontrol: any;

	constructor(
		private router: Router,
		private messageService: MessageServiceService,
		public globals: Globals,
		private zone: NgZone,
		private cookieService: CookieService,
		private toastr: ToastrService,
		private location: Location,
	) {
		// console.log(this.router.url);
		// console.log(this.location.path());
		if (this.router.url === '/' && this.location.path() === '') {
			this.SolGizli = true;
		}

		if (this.cookieService.check('Token')) {
			this.globals.User.Token = this.cookieService.get('Token');
			this.globals.User.Decoded = jwt_decode(this.globals.User.Token);
			console.log(this.globals.User.Decoded);
			// console.log(this.router);
			if (this.router.url === '/' && this.location.path() === '') {
				this.router.navigateByUrl('/gelen');
				this.SolGizli = false;
			}
			if (this.router.url === '/' && this.location.path() === '/home') {
				this.router.navigateByUrl('/gelen');
				this.SolGizli = false;
			}
			if (this.router.url === '/' && this.location.path() !== '' && this.location.path() !== '/home') {
				// this.router.navigateByUrl('/gelen');
				this.router.navigateByUrl(this.location.path());
				this.SolGizli = false;
			}

			if(this.globals.User.Decoded.IsEditor == 0)
			{
				window.location.href = 'https://uyelik.effieturkiye.org/home/ebookeditor';
			}
			
		}
		else {
			window.location.href = 'https://uyelik.effieturkiye.org/home/ebookeditor';
		}


		this.subscription = this.messageService.getMessage().subscribe(message => {
			this.message = message;
			if (this.message.text === 'logout') {

				this.loggedin = false;
				this.globals.Sifirla();
				localStorage.clear();
				this.cookieService.deleteAll('/', 'effieturkiye.org');
				// this.globals.User = null;
				// this.globals.projeler = [];
				window.location.href = 'https://uyelik.effieturkiye.org/home/ebookeditor';
				// this.router.navigateByUrl('/home');
				// this.toastr.success('Çıkış Yaptınız');

			}
		});
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}
