import { Component, OnInit, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Globals } from '../providers/globals/globals';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Subscription } from 'rxjs/Subscription';
import { MessageServiceService } from '../services/message-service.service';
import { HttpClient, HttpHandler, HttpRequest, HttpParams, HttpHeaders } from '@angular/common/http';
import { saveAs } from 'file-saver';

@Component({
	selector: 'app-dosyaolustur',
	templateUrl: './dosyaolustur.component.html',
	styleUrls: ['./dosyaolustur.component.css']
})
export class DosyaOlusturComponent implements OnInit {
	subscription: Subscription;
	GlobalYonetim: any;
	EntryId;
	PageData: any;
	Yuklendi = false;
	isLoading = false;
	PDFHtml3 = '';
	Sorular: any = [];
	Dosyalar = [];
	Yil = '';
	ProjeListesi:number[] = [];
	SeciliProje:any = {
		ProjeId: 0,
		Logo: '',
		Dosyalar: []
	};

	constructor(

		private route: ActivatedRoute,
		private router: Router,
		private toastr: ToastrService,
		private globals: Globals,
		private messageService: MessageServiceService,
		private zone: NgZone,
		private http: HttpClient,
	) {

	}

	hasBaseDropZoneOver = false;
	fileOverBase(e: any): void {
		this.hasBaseDropZoneOver = e;
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	ngOnInit() {
		this.GetData();
	}

	GetData() {
		const params = new HttpParams();
		let headers = new HttpHeaders();
		headers = headers.append('Accept', 'application/json, text/javascript, */*; q=0.01');
		headers = headers.append('Content-Type', 'application/json');
		headers = headers.append('Authorization', 'Bearer ' + this.globals.User.Token);

		this.http.get('https://api.effieturkiye.org/GetDosyalar2022', { params, headers }).subscribe(
			(response) => {
				this.PageData = response;
				// console.log(this.PageData);
				this.PageData.forEach(element => {
					if(!this.ProjeListesi.includes(element.ProjeId)){
						this.ProjeListesi.push(element.ProjeId);
					}
				});
				// console.log(this.ProjeListesi);
			},
			error => {
				console.log('Error', error);
				if (error.status === 401) {
					// localStorage.clear();
					// this.globals.Sifirla();
					// this.router.navigateByUrl('/home');
					this.messageService.sendMessage('logout');
				}
				// this.showError('Bir hata oluştu, Lütfen tekrar deneyiniz');
			}
		);
	}

	DosyaSec(ProjeId:string){
		this.PDFHtml3 = '';
		this.SeciliProje = {
			ProjeId: 0,
			Logo: '',
			Dosyalar: []
		};
		let result = this.PageData.filter(function (elem, i, rep) {
			return elem.ProjeId === this;
		}, parseInt(ProjeId));
		console.log(result[0])
		this.SeciliProje["ProjeId"] = ProjeId;
		this.SeciliProje["Logo"] = result[0]["Logo"];
		this.SeciliProje["Dosyalar"] = [];
		result.forEach(element => {
			this.SeciliProje["Dosyalar"].push(element);
		});
		console.log(this.SeciliProje);
		this.HtmlOlustur();
	}

	HtmlOlustur() {
		this.PDFHtml3 = '';
		this.PDFHtml3 += '<html><head><meta http-equiv="content-type" content="text/html; charset=UTF-8"></head><body>';
		this.PDFHtml3 += '<style>p{margin-top:0px; margin-bottom: 0px;padding-top:0px; padding-bottom: 0px;}img{max-width:100%;}</style>';
		// this.PDFHtml3 = '<table style="width: 580px; height: 40px; background-color: #000; border-collapse: collapse;" align="center" border = "0">'
		this.PDFHtml3 += '<table style="width: 580px; height: 0px; background-color: #000; border-collapse: collapse; display:none;" align="center" border = "0">'
		this.PDFHtml3 += '<tr>'
		this.PDFHtml3 += '<td style="width:125px; height: 40px;" ><img style="width:100px; height: 40px;" src="http://api.effieturkiye.org/storage/blackbackground.png" /></td>'
		this.PDFHtml3 += '<td valign = "middle" style = "width:380px; height: 40px; vertical-align: middle; color:#fff; font-size: 16px; line-height: 60px; text-align:center;">Effie 2022 Başvuru Formu</td>'
		this.PDFHtml3 += '<td style = "width:125px; height: 40px; vertical-align: middle; color:#fff; font-size: 16px; text-align:center;">&nbsp;</td>'
		this.PDFHtml3 += '</tr>'
		this.PDFHtml3 += '</table>';
		this.PDFHtml3 += '<img style="height: 0px; display: none;" src="http://api.effieturkiye.org/storage/Effie_PDF_LOGO_LEFT2.png" />';
		this.PDFHtml3 += '<img style="height: 0px; display: none;" src="http://api.effieturkiye.org/storage/Effie_PDF_LOGO_RIGHT2.png" />';
		this.PDFHtml3 += '<img style="height: 0px; display: none;" src="http://api.effieturkiye.org/storage/logolar/' + this.SeciliProje.Logo + '" />';

		this.PDFHtml3 += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
		this.PDFHtml3 += '<tbody>'
		this.PDFHtml3 += '<tr><td style="color:#fff; font-size: 14px; text-align:center;">Dosyalar</td></tr>'
		this.PDFHtml3 += '</tbody>'
		this.PDFHtml3 += '</table>'
		this.PDFHtml3 += '<table style="width: 580px; background-color:#fff;border: 1px solid #b1985c;" align="center" border="0">'
		this.PDFHtml3 += '<tbody>'
		this.SeciliProje.Dosyalar.forEach((item: any) => {
			this.PDFHtml3 += '<tr style="font-size: 10px; background-color:#fff;border: 1px solid #b1985c;">';
			if (item.Type !== 'Link') {
				this.PDFHtml3 += '<td><a style="font-size: 10px;" target="_blank" href="https://api.effieturkiye.org/storage/dosyalar/' + this.SeciliProje.ProjeId + '/' + encodeURIComponent(item.DosyaAdi) + '">' + item.DosyaAdi + '</td>';
			}
			else {
				this.PDFHtml3 += '<td><a style="font-size: 10px;" href="' + item.DosyaAdi + '" target="_blank">' + item.DosyaAdi + '</a></td>';
			}
			this.PDFHtml3 += '</tr>';
		});

		this.PDFHtml3 += '</tbody>'
		this.PDFHtml3 += '</table>'
		this.PDFHtml3 += '<br>';
		this.PDFHtml3 += '</body></html>';
		console.log({ ProjectId: this.SeciliProje.ProjeId.toString(), icerik: this.PDFHtml3, Logo: this.SeciliProje.Logo });
		// console.log(this.PDFHtml3);
		// let nameString = "Çalışan_Türkiye_Marangoz_Film";
		// console.log(encodeURIComponent(nameString));
		this.SayfaOlustur();
	}

	SayfaOlustur() {

		this.showWarning("PDF Oluşturuluyor. Zaman alabilir lütfen bekleyiniz.");
		this.isLoading = true;
		var params = new HttpParams();
		var headers = new HttpHeaders()
			.append('Content-Type', 'application/json; charset=utf-8');
		// http://localhost:3000/
		this.http.post("https://pdfebook.effieturkiye.org/dosya", { ProjectId: this.SeciliProje.ProjeId.toString(), icerik: this.PDFHtml3, Logo: this.SeciliProje.Logo }, {
			params: params,
			headers: headers
		})
			.subscribe(data => {
				this.isLoading = false;
				
				this.showSuccess("PDF Oluşturuldu. Son Versiyonu indire basarak indirebilirsiniz.");
				// window.location.href = "http://localhost:3000/pdf/pdf/" + this.SeciliProje.ProjeId + "/1.pdf";
			},
				err => {
					this.isLoading = false;
					this.showError("Bir hata oluştu, Lütfen tekrar deneyiniz");
				});
	}

	showSuccess(mesaj: string) {
		this.toastr.success(mesaj, 'Tebrikler!');
	}

	showError(mesaj: string) {
		this.toastr.error(mesaj, 'Hata!');
	}

	showWarning(mesaj: string) {
		this.toastr.warning(mesaj, 'Dikkat!');
	}

	showInfo(mesaj: string) {
		this.toastr.info(mesaj);
	}


	formatBytes(bytes, decimals = 2) {
		if (bytes === 0) return '0 Bytes';

		const k = 1024;
		const dm = decimals < 0 ? 0 : decimals;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

		const i = Math.floor(Math.log(bytes) / Math.log(k));

		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	}

	DosyaType(text) {
		if (text === 'text/plain') {
			text = "txt"
		}
		else if (text === "application/msword") {
			text = "Word"
		} else if (text === "image/jpeg") {
			text = "Resim"
		}
		else if (text === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
			text = "Excel"
		}
		else if (text === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
			text = "Word"
		}
		else {

		}
		return text
	}

	downloadFile(id) {
		saveAs.saveAs("https://pdfebook.effieturkiye.org/pdfler/"+id+"/4.pdf", id + ".pdf");
	}
	downloadFileDosyalar(id) {
		saveAs.saveAs("https://pdfebook.effieturkiye.org/pdfler/"+id+"/"+id+"_merged.pdf", id + "_merged.pdf");
	}
}
